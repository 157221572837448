import "../../assets/css/complianceSetup/complianceSetup.css"
import save from "../../assets/images/save.svg"

import addProfilePhoto from "../../assets/images/uploadPicture.svg"

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Tab, Tabs } from "react-bootstrap"
import Swal from "sweetalert2"

import InformationComplianceFirstTime from "../tabsFirstTime/informationComplianceFirstTime.jsx"
import DocumentComplianceTable from "../tabs/documentCompliance/documentComplianceTable.jsx"
import QualitySurveyFirstTime from "../tabsFirstTime/qualitySurveyFirstTime.jsx"
import SubmissionFirstTime from "../tabsFirstTime/submissionFirstTime.jsx";
import axios from "axios";
import { useCookies } from "react-cookie";
import ProductsAndServices from "../tabs/productAndService.jsx";
import { getAllIndustries } from "../tabs/documentCompliance/utils.js";
import StaffList from "../tabs/staff/staffList.jsx";

const URI_SUPPLIER = process.env.REACT_APP_URI_SUPPLIERS
const URI_SERVER_DATE = process.env.REACT_APP_URI_SERVER_DATE
const URI_UPLOADS = process.env.REACT_APP_URI_UPLOADS

export default function ComplianceSetup() {
	const [cookie] = useCookies(["userLogged"])
	const userLogged = cookie.userLogged

	const [supplier, setSupplier] = useState(null);
	const [serverDate, setServerDate] = useState(null);
	const [allIndustries, setAllIndustries] = useState(null)

	const [key, setKey] = useState(0);

	const navigate = useNavigate();

	useEffect(() => {
		getServerDate();
		getSupplierByID(userLogged && userLogged.dataID);
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			try {
				const industriesData = await getAllIndustries()
				setAllIndustries(industriesData)

			} catch (error) {
				console.error("Error fetching data: ", error)
			}
		}

		fetchData()
	}, [])

	const handleSaveAndSubmitInfo = (updatedSupplier, keyRecived) => {
		!keyRecived && Swal.fire({
			toast: true,
			footer: 'Loading, please wait',
			showConfirmButton: false,
			// allowOutsideClick: false,
			// allowEscapeKey: false,
			position: "bottom-end",
			didOpen: () => {
				Swal.showLoading()
			},
		})

		keyRecived && Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		const formData = new FormData();
		const supplierToSave = updatedSupplier

		formData.append("rfc", supplierToSave.informationCompliance.generalInformation.rfc) //This is specific to recive the rfc to save files by carpets
		let saveWithFile = false
		const filesToSave = []

		if (supplierToSave.informationCompliance.generalInformation.curriculum.document?.name) {
			saveWithFile = true
			const fileToSave = supplierToSave.informationCompliance.generalInformation.curriculum.document
			const fileNewName = `${supplierToSave.informationCompliance.generalInformation.curriculum.yearCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.monthCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
			filesToSave.push({
				file: fileToSave,
				fileNewName: fileNewName
			})
			supplierToSave.informationCompliance.generalInformation.curriculum.checkThis = true
		}

		supplierToSave.informationCompliance.generalInformation.curriculum.uploadedAt = supplierToSave.informationCompliance.generalInformation.curriculum.uploadedAt ? supplierToSave.informationCompliance.generalInformation.curriculum.document && supplierToSave.informationCompliance.generalInformation.curriculum.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : supplierToSave.informationCompliance.generalInformation.curriculum.document ? supplierToSave.informationCompliance.generalInformation.curriculum.uploadedAt : "" : supplierToSave.informationCompliance.generalInformation.curriculum.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

		supplierToSave.informationCompliance.generalInformation.curriculum.document = supplierToSave.informationCompliance.generalInformation.curriculum.document && (supplierToSave.informationCompliance.generalInformation.curriculum.document.name ? `${supplierToSave.informationCompliance.generalInformation.curriculum.yearCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.monthCreatedAt}-${supplierToSave.informationCompliance.generalInformation.curriculum.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : supplierToSave.informationCompliance.generalInformation.curriculum.document)

		if (supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name) {
			saveWithFile = true
			const fileToSave = supplierToSave.informationCompliance.generalInformation.profilePhoto.document
			const fileNewName = `${serverDate.year}-${serverDate.month}-${supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
			filesToSave.push({
				file: fileToSave,
				fileNewName: fileNewName
			})
			supplierToSave.informationCompliance.generalInformation.profilePhoto.checkThis = true
		}
		supplierToSave.informationCompliance.generalInformation.profilePhoto.uploadedAt = supplierToSave.informationCompliance.generalInformation.profilePhoto.uploadedAt ? supplierToSave.informationCompliance.generalInformation.profilePhoto.document && supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : supplierToSave.informationCompliance.generalInformation.profilePhoto.document ? supplierToSave.informationCompliance.generalInformation.profilePhoto.uploadedAt : "" : supplierToSave.informationCompliance.generalInformation.profilePhoto.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`

		supplierToSave.informationCompliance.generalInformation.profilePhoto.document = supplierToSave.informationCompliance.generalInformation.profilePhoto.document && (supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name ? `${serverDate.year}-${serverDate.month}-${supplierToSave.informationCompliance.generalInformation.profilePhoto.document?.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : supplierToSave.informationCompliance.generalInformation.profilePhoto.document)

		supplierToSave.documentCompliance.genericDocuments.general.forEach(document => {
			if (document.document && document.document.name) {
				saveWithFile = true
				const fileToSave = document.document
				const fileNewName = `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
				filesToSave.push({
					file: fileToSave,
					fileNewName: fileNewName
				})
				document.checkThis = true
			}
			document.uploadedAt = document.uploadedAt ? document.document && document.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : document.document ? document.uploadedAt : "" : document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
			document.document = document.document && (document.document.name ? `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)
		});

		supplierToSave.documentCompliance.genericDocuments.periodic.forEach(document => {
			if (document.document && document.document.name) {
				saveWithFile = true
				const fileToSave = document.document
				const fileNewName = `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
				filesToSave.push({
					file: fileToSave,
					fileNewName: fileNewName
				})
				document.checkThis = true
			}
			document.uploadedAt = document.uploadedAt ? document.document && document.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : document.document ? document.uploadedAt : "" : document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
			document.document = document.document && (document.document.name ? `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)
		});

		supplierToSave.documentCompliance.clients.forEach(client => {
			client.clientDocuments.general.forEach(document => {
				if (document.document && document.document.name) {
					saveWithFile = true
					const fileToSave = document.document
					const fileNewName = `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
					filesToSave.push({
						file: fileToSave,
						fileNewName: fileNewName
					})
					document.checkThis = true
				}
				document.uploadedAt = document.uploadedAt ? document.document && document.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : document.document ? document.uploadedAt : "" : document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
				document.document = document.document && (document.document.name ? `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)
			})

			client.clientDocuments.periodic.forEach(document => {
				if (document.document && document.document.name) {
					saveWithFile = true
					const fileToSave = document.document
					const fileNewName = `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
					filesToSave.push({
						file: fileToSave,
						fileNewName: fileNewName
					})
					document.checkThis = true
				}
				document.uploadedAt = document.uploadedAt ? document.document && document.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : document.document ? document.uploadedAt : "" : document.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
				document.document = document.document && (document.document.name ? `${serverDate.year}-${serverDate.month}-${document.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : document.document)
			})
		});

		supplierToSave.staffs.forEach(staff => {
			if (staff.profilePhoto?.document && staff.profilePhoto?.document.name) {
				saveWithFile = true
				const fileToSave = staff.profilePhoto?.document
				const fileNewName = `${serverDate.year}-${serverDate.month}-${staff.profilePhoto?.document?.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
				filesToSave.push({
					file: fileToSave,
					fileNewName: fileNewName
				})
				staff.profilePhoto.checkThis = true
			}
			if (staff.profilePhoto) {
				staff.profilePhoto.uploadedAt = staff.profilePhoto?.uploadedAt ? staff.profilePhoto?.document && staff.profilePhoto?.document?.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : staff.profilePhoto?.document ? staff.profilePhoto?.uploadedAt : "" : staff.profilePhoto?.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
				staff.profilePhoto.document = staff.profilePhoto?.document && (staff.profilePhoto?.document?.name ? `${serverDate.year}-${serverDate.month}-${staff.profilePhoto?.document?.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : staff.profilePhoto?.document)
			}
		})
		supplierToSave.productsAndServices.products.forEach(product => {
			if (product.drawing.document && product.drawing.document.name) {
				saveWithFile = true
				const fileToSave = product.drawing.document
				const fileNewName = `${product.drawing.yearCreatedAt}-${product.drawing.monthCreatedAt}-${product.drawing.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
				filesToSave.push({
					file: fileToSave,
					fileNewName: fileNewName
				})
				product.drawing.checkThis = true
			}
			product.drawing.uploadedAt = product.drawing.uploadedAt ? product.drawing.document && product.drawing.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : product.drawing.document ? product.drawing.uploadedAt : "" : product.drawing.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
			product.drawing.document = product.drawing.document && (product.drawing.document.name ? `${product.drawing.yearCreatedAt}-${product.drawing.monthCreatedAt}-${product.drawing.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : product.drawing.document)

			if (product.picture.document && product.picture.document.name) {
				saveWithFile = true
				const fileToSave = product.picture.document
				const fileNewName = `${product.picture.yearCreatedAt}-${product.picture.monthCreatedAt}-${product.picture.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
				filesToSave.push({
					file: fileToSave,
					fileNewName: fileNewName
				})
				product.picture.checkThis = true
			}
			product.picture.uploadedAt = product.picture.uploadedAt ? product.picture.document && product.picture.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : product.picture.document ? product.picture.uploadedAt : "" : product.picture.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
			product.picture.document = product.picture.document && (product.picture.document.name ? `${product.picture.yearCreatedAt}-${product.picture.monthCreatedAt}-${product.picture.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : product.picture.document)

		})
		supplierToSave.qualitySurvey.certifications.forEach(certification => {
			if (certification.certification.document && certification.certification.document.name) {
				saveWithFile = true
				const fileToSave = certification.certification.document
				const fileNewName = `${serverDate.year}-${serverDate.month}-${certification.certification.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`
				filesToSave.push({
					file: fileToSave,
					fileNewName: fileNewName
				})
				certification.certification.checkThis = true
			}
			certification.certification.uploadedAt = certification.certification.uploadedAt ? certification.certification.document && certification.certification.document.name ? `${serverDate.month}/${serverDate.day}/${serverDate.year}` : certification.certification.document ? certification.certification.uploadedAt : "" : certification.certification.document && `${serverDate.month}/${serverDate.day}/${serverDate.year}`
			certification.certification.document = certification.certification.document && (certification.certification.document.name ? `${serverDate.year}-${serverDate.month}-${certification.certification.document.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}` : certification.certification.document)
		})
		if (keyRecived) {
			const clientIDs = []

			supplierToSave.informationCompliance.generalInformation.profilePhoto.yearCreatedAt = serverDate.year
			supplierToSave.informationCompliance.generalInformation.profilePhoto.monthCreatedAt = serverDate.month
			supplierToSave.informationCompliance.generalInformation.profilePhoto.dayCreatedAt = serverDate.day

			supplierToSave.clients.forEach(client => {
				if (client.status === "Ready for review") {
					clientIDs.push(client.clientID)
				}
			})

			supplierToSave.qualitySurvey.certifications.forEach(certification => {
				certification.certification.yearCreatedAt = serverDate.year
				certification.certification.monthCreatedAt = serverDate.month
				certification.certification.dayCreatedAt = serverDate.day
			});

			formData.append("sendEmail", true)
			formData.append("supplierClientID", JSON.stringify(clientIDs))
			formData.append("rol", userLogged.rol)
			formData.append("dataID", userLogged.dataID)
		}

		saveWithFile && filesToSave.forEach(doc => {
			formData.append("document", doc.file, doc.fileNewName)
		})
		formData.append("saveWithFile", saveWithFile)
		formData.append("supplier", JSON.stringify(supplierToSave))

		axios.put(URI_SUPPLIER + userLogged.dataID, formData).then(response => {
			setSupplier(response.data.supplier)
			Swal.fire({
				toast: true,
				icon: !saveWithFile || (saveWithFile && response.data.fileSaved) ? 'success' : "error",
				text: !saveWithFile || (saveWithFile && response.data.fileSaved) ? 'Information saved successfully' : "Failed to save information",
				showConfirmButton: false,
				timer: 2000,
				timerProgressBar: true,
				position: "bottom-end",
			})

			if (keyRecived) {
				navigate("/home")
				navigate(0)
			}
		}).catch(err => {
			Swal.fire({
				icon: 'error',
				text: 'Failed to save information',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			console.log("Error al enviar la informacion del supplier: " + err)
		})
	}

	// GET THE SERVER DATE
	const getServerDate = () => {
		axios.get(URI_SERVER_DATE).then(response => {
			setServerDate(response.data)
		}).catch(err => {
			console.log("Error al obtener la fecha del servidor" + err)
		})
	}

	// GET THE SUPPLIER BY THE ID
	const getSupplierByID = (id) => {
		axios.get(URI_SUPPLIER + id).then(response => {
			setSupplier(response.data)
		}).catch(err => {
			console.log("Error al obtener el supplier por id:" + err)
		})
	}

	// FUNCTION TO HANDLE DE PROFILE PICTURE CHANGE
	const handleProfilePictureChange = (element) => {
		if (element.files[0].name) {
			supplier.informationCompliance.generalInformation.profilePhoto.document = element.files[0]

			handleSaveAndSubmitInfo(supplier, false)
		}
	}

	return (
		<section className="complianceSetup">
			<div className="header">
				<div className="profilePhotoContainer">
					<input className="inputProfilePhoto" type="file" onChange={(e) => handleProfilePictureChange(e.target)} />
					<img className="profilePhoto" src={
						supplier && supplier.informationCompliance.generalInformation.profilePhoto.document && !supplier.informationCompliance.generalInformation.profilePhoto.document.name
							? process.env.REACT_APP_URI_UPLOADS + supplier.informationCompliance.generalInformation.rfc + "/" + supplier.informationCompliance.generalInformation.profilePhoto.document
							: addProfilePhoto
					} alt="Prodensa Supplier" />
				</div>
				<div className="titleLocation">
					<h3>{supplier && supplier.informationCompliance.generalInformation.companyName}</h3>
					<span>
						{supplier &&
							`${supplier.informationCompliance.generalInformation.contactInformation?.name},
							${supplier.informationCompliance.generalInformation.address.city},
							${supplier.informationCompliance.generalInformation.address.state}`}
					</span>
				</div>
			</div>

			<Tabs
				id="controlled-tab-example"
				activeKey={key}
				onSelect={(k) => setKey(k)}
			>
				<Tab eventKey={0} title="1 Information Completed">
					{supplier && serverDate && <InformationComplianceFirstTime
						informationCompliance={supplier.informationCompliance}
						setSupplier={setSupplier}
						username={userLogged && userLogged.username}
						serverDate={serverDate}
					/>}
				</Tab>
				<Tab eventKey={1} title="2 Document compliance">
					{supplier && <DocumentComplianceTable
						supplier={supplier}
						serverDate={serverDate}
						handleSaveChanges={handleSaveAndSubmitInfo}
						complianceSetup={true}
						userLogged={userLogged}
					/>}
				</Tab>
				{supplier?.informationCompliance?.generalInformation?.hasRepse && (
					<Tab eventKey={2} title="3 Staff">
						{supplier && <StaffList
							supplier={supplier}
							rfc={supplier.informationCompliance.generalInformation.rfc}
							handleSaveChanges={handleSaveAndSubmitInfo}
						/>}
					</Tab>
				)}
				<Tab eventKey={3} title="4 Quality Survey">
					{supplier && <QualitySurveyFirstTime
						supplier={supplier}
						qualitySurvey={supplier.qualitySurvey}
						setSupplier={setSupplier}
						rfc={supplier.informationCompliance.generalInformation.rfc}
						handleSaveChanges={handleSaveAndSubmitInfo}
					/>}
				</Tab>
				<Tab eventKey={4} title="5 Products/Services">
					{supplier && serverDate && <ProductsAndServices
						supplier={supplier}
						productsAndServices={supplier.productsAndServices}
						setSupplier={setSupplier}
						serverDate={serverDate}
						userLogged={userLogged}
						rfc={supplier.informationCompliance.generalInformation.rfc}
						firstTime
						handleSaveChanges={handleSaveAndSubmitInfo}
					/>}
				</Tab>
				<Tab eventKey={5} title="6 Submission">
					{supplier && serverDate && <SubmissionFirstTime
						supplier={supplier}
						serverDate={serverDate}
					/>}
				</Tab>
			</Tabs>

			<div className="actionButtons">
				{+key === 5 && <p className="terms">I hereby certify that all the information is true and can be verified. I have read and agree to the <a href={URI_UPLOADS + "privacyPolicy/avisoPrivacidad.pdf"} target="_blank">Privacy Policy</a> and <a href="#">Terms and Conditions.</a></p>}
				{+key < 5 && <button className="btnBgBlueTextGradient" onClick={() => handleSaveAndSubmitInfo(supplier, false)}>Save draft</button>}
				{+key < 5 && <button className="btnBgBlueTextGradient" disabled={!allIndustries} onClick={() => +key < 5 ? setKey(+key + 1) : undefined}>Next</button>}
			</div>
			<button className="saveChanges" disabled={!allIndustries} onClick={() => handleSaveAndSubmitInfo(supplier, false)}>
				<img src={save} alt="Prodensa Supplier" />
			</button>

		</section>
	)
}