import "../../assets/css/clientsAdminView/userControl.css"

import close from "../../assets/images/close.svg"

import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { checkTheEmail } from "../../utilities/validateEmail";
import Swal from "sweetalert2";

const URI_USER = process.env.REACT_APP_URI_USERS
const URI_USERS_BY_DATA_ID = process.env.REACT_APP_URI_USERS_FILTER_BY_DATA_ID
const URI_RESET_PASSWORD = process.env.REACT_APP_URI_RESET_PASSWORD

export default function UsersControlModal(props) {
	// VARIABLE TO MANAGE THE NEW USERNAME FOR THE ADMIN CLIENT
	const [clientNewUsername, setClientNewUsername] = useState("")

	// VARIABLE TO MANAGE THE NEW USERNAME FOR THE ENTITY
	const [entitiesNewUser, setEntitiesNewUser] = useState(null)

	// VARIABLE TO MANAGE THE USERS OF THE CLIENT
	const [users, setUsers] = useState(null)

	useEffect(() => {
		getClientUsers()

		setEntitiesNewUser(props.client.entities.map(entity => {
			return {
				entityID: entity._id,
				entityNewUser: ""
			}
		}))
	}, [])

	// FUNCTION TO GET THE CLIENT USERNAMES
	const getClientUsers = async () => {
		const idsToFind = [props.client._id, ...props.client.entities.map(entity => entity._id)]

		const usersFounded = (await axios.post(URI_USERS_BY_DATA_ID, { idsToFind: idsToFind })).data

		if (usersFounded) {
			setUsers(usersFounded)
		}
	}

	// FNTION TO HANDLE THE NEW USERNAMES FOR THE ENTITIES
	const handleEntityNewUsername = (entityID, value) => {
		const entitiesNewUserCopy = JSON.parse(JSON.stringify(entitiesNewUser))

		entitiesNewUserCopy.find(entityCopy => entityCopy.entityID === entityID).entityNewUser = value

		setEntitiesNewUser(entitiesNewUserCopy)
	}

	// FUNCTION TO RESET THE VALUES OF NEW USERNAME ON CANCEL THE MODAL
	const handleCancelNewUsers = () => {
		setClientNewUsername("")
		setEntitiesNewUser(prevState => prevState.map(state => {
			return {
				...state,
				entityNewUser: ""
			}
		}))

		props.onHide()
	}

	//FUNCTION TO HANDLE THE RESET PASSWORD BUTTON
	const handleResetPassword = (username) => {
		Swal.fire({
			title: "Are you sure you want to reset this password?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: "Reset password",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					footer: 'Loading, please wait',
					showConfirmButton: false,
					allowOutsideClick: false,
					allowEscapeKey: false,
					didOpen: () => {
						Swal.showLoading()
					},
				})

				axios.put(URI_RESET_PASSWORD, {
					email: username
				}).then(response => {
					if (response.data.founded) {
						Swal.fire({
							icon: 'success',
							text: 'Password reseted successfully',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						})
					} else {
						Swal.fire({
							icon: 'error',
							text: 'Email does not exist',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						})
					}
				}).catch(err => {
					Swal.fire({
						icon: 'error',
						text: 'Failed to reset the password',
						showConfirmButton: false,
						timer: 5000,
						timerProgressBar: true,
					})
					console.log("Error al resetear la contraseña del cliente: " + err)
				})
			}
		});
	}

	// FUNCTION TO HANDLE TO SAVE THE NEW USERNAMES FOR THE USERS
	const handleSaveUsers = () => {
		Swal.fire({
			footer: 'Loading, please wait',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading()
			},
		})

		try {
			if (clientNewUsername) {
				const clientUserID = users.find(user => user.dataID === props.client._id)._id

				axios.put(URI_USER + clientUserID, { newUsername: clientNewUsername })
			}

			entitiesNewUser.filter(entity => entity.entityNewUser).forEach(entity => {
				const entityUserID = users.find(user => user.dataID === entity.entityID)?._id

				if (entityUserID) {
					axios.put(URI_USER + entityUserID, { newUsername: entity.entityNewUser })
				} else {
					axios.post(URI_USER, {
						username: entity.entityNewUser,
						rol: "Client",
						dataID: entity.entityID,
						activePurchasing: users.find(user => user.dataID === props.client._id).activePurchasing
					})
				}
			});

			Swal.fire({
				icon: 'success',
				text: 'Users saved successfully',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})

			getClientUsers()
			handleCancelNewUsers()
		} catch (err) {
			Swal.fire({
				icon: 'error',
				text: 'Failed to save the users',
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
			})
			console.log("Error al salvar los usuarios: " + err)
		}
	}

	return (
		<Modal
			show={props.show}
			onHide={props.onHide}
			size="xl"
			centered
			className="usersControlModal">

			<Modal.Body>
				<img className="closeModal" src={close} onClick={handleCancelNewUsers} alt="Prodensa Supplier" />
				<h3 className="modalTitle">Client usernames</h3>
				<table className="usersTable">
					<thead>
						<tr>
							<th><span>Name</span></th>
							<th><span>Actual Username</span></th>
							<th><span>New Username</span></th>
							<th><span>Reset Password</span></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div className="infoContainer">
									<span>{props.client.generalInformation.clientName}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{users?.find(user => user.dataID === props.client._id)?.username ?? "User not created"}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<input type="text" placeholder="New username" value={clientNewUsername}
										onChange={(e) => setClientNewUsername(e.target.value)}
										onBlur={() => setClientNewUsername(checkTheEmail(clientNewUsername))} />
								</div>
							</td>
							<td>
								<div className="infoContainer">
									{users?.find(user => user.dataID === props.client._id)
										? <button className="btnBgBlueTextGradient"
											onClick={() => handleResetPassword(users?.find(user => user.dataID === props.client._id)?.username)}>Reset</button>
										: <span>User not created</span>}
								</div>
							</td>
						</tr>
						{props.client.entities.map((entity, index) => {
							if (entitiesNewUser?.find(entityNewUser => entityNewUser.entityID === entity._id)) {
								return (
									<tr key={index}>
										<td>
											<div className="infoContainer">
												<span>{entity.entityName}</span>
											</div>
										</td>
										<td>
											<div className="infoContainer">
												<span>{users?.find(user => user.dataID === entity._id)?.username ?? "User not created"}</span>
											</div>
										</td>
										<td>
											<div className="infoContainer">
												<input type="text" placeholder="New username" value={entitiesNewUser?.find(entityNewUser => entityNewUser.entityID === entity._id).entityNewUser}
													onChange={(e) => handleEntityNewUsername(entity._id, e.target.value)}
													onBlur={() => handleEntityNewUsername(entity._id, checkTheEmail(entitiesNewUser?.find(entityNewUser => entityNewUser.entityID === entity._id).entityNewUser))} />
											</div>
										</td>
										<td>
											<div className="infoContainer">
												{users?.find(user => user.dataID === entity._id)
													? <button className="btnBgBlueTextGradient"
														onClick={() => handleResetPassword(users?.find(user => user.dataID === entity._id)?.username)}>Reset</button>
													: <span>User not created</span>}
											</div>
										</td>
									</tr>
								)
							}
						})}
					</tbody>
				</table>

				<div className="actions">
					<button className="btnBgWhiteTextBlack" onClick={handleCancelNewUsers}>Cancel</button>
					<button className="btnBgBlueTextGradient" onClick={handleSaveUsers}>Save users</button>
				</div>
			</Modal.Body>
		</Modal>
	)
}