// import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import "../../assets/css/securityStaff/visitsList.css";
import companyIcon from "../../assets/images/companyIcon.svg";
import entityIcon from "../../assets/images/entityIcon.svg";
import searchIcon from "../../assets/images/search.svg";
import calendarIcon from "../../assets/images/calendarIcon.svg";
import printReport from "../../assets/images/printReport.svg";
import calendarArrowLeftIcon from "../../assets/images/calendarArrowLeftIcon.svg";
import calendarArrowRightIcon from "../../assets/images/calendarArrowRightIcon.svg";

import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import Swal from "sweetalert2";
import axios from "axios"

const URI_REPORT_VISITS_REQUEST = process.env.REACT_APP_URI_GET_VISITS_REPORT
const URI_UPLOADS = process.env.REACT_APP_URI_UPLOADS

export default function VisitsList(props) {
    const [cookie] = useCookies(["userLogged"]);
    const userLogged = cookie.userLogged;

    const { searchText } = props;

    const [staffList, setStaffList] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);

    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [filteredVisits, setFilteredVisits] = useState([]);
    const filterRef = useRef(null);
    const staffListRef = useRef(null);

    const [tempSelectedCompanies, setTempSelectedCompanies] = useState([]);
    const [tempSelectedEntities, setTempSelectedEntities] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);

    const [companySearchText, setCompanySearchText] = useState("");
    const [entitySearchText, setEntitySearchText] = useState("");

    const [isCompanyFilterOpen, setCompanyFilterOpen] = useState(false);
    const [isEntityFilterOpen, setEntityFilterOpen] = useState(false);
    const [isDateFilterOpen, setDateFilterOpen] = useState(false);

    const [companyOptions, setCompanyOptions] = useState([]);
    const [entityOptions, setEntityOptions] = useState([]);

    useEffect(() => {
        fetchVisits();
    }, []);

    const fetchVisits = async () => {
        const dataID = userLogged.dataID;

        try {
            const response = await fetch(process.env.REACT_APP_URI_GET_VISITS, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ dataID })
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            setStaffList(data);
            setFilteredVisits(data);

            // Extraer opciones únicas para los filtros
            const uniqueCompanies = [...new Set(data.map(visit => visit.companyName))];
            setCompanyOptions(uniqueCompanies);

        } catch (error) {
            console.error("Error fetching visits:", error);
            Swal.fire("Error", "Unable to fetch visits data.", "error");
        }
    };

    useEffect(() => {
        const filteredStaff = staffList.filter(staff => {
            const companyMatch = selectedCompanies.length === 0 || selectedCompanies.includes(staff.companyName);
            const entityMatch = selectedEntities.length === 0 || selectedEntities.includes(staff.entityName);
            const searchMatch = staff.staffName.toLowerCase().includes(searchText.toLowerCase()) ||
                staff.guardName?.toLowerCase().includes(searchText.toLowerCase());
            const dateMatch = dateRange[0] === null || dateRange[1] === null || (
                new Date(staff.date) >= dateRange[0] &&
                new Date(staff.date) <= dateRange[1]
            );

            return companyMatch && entityMatch && searchMatch && dateMatch;
        });
        setFilteredVisits(filteredStaff);
    }, [searchText, selectedCompanies, selectedEntities, staffList]);

    useEffect(() => {
        if (selectedCompanies.length > 0) {
            // Filtrar las entidades según las compañías seleccionadas
            const filteredEntities = staffList
                .filter(staff => selectedCompanies.includes(staff.companyName))
                .map(staff => staff.entityName);

            const uniqueEntities = [...new Set(filteredEntities)];
            setEntityOptions(uniqueEntities);
        } else {
            // Si no hay compañías seleccionadas, mostrar todas las entidades
            const allEntities = staffList.map(staff => staff.entityName);
            const uniqueEntities = [...new Set(allEntities)];
            setEntityOptions(uniqueEntities);
        }
    }, [selectedCompanies, staffList]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                staffListRef.current &&
                filterRef.current &&
                !staffListRef.current.contains(event.target) &&
                !filterRef.current.contains(event.target)
            ) {
                setCompanyFilterOpen(false);
                setEntityFilterOpen(false);
                setDateFilterOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    useEffect(() => {
        filterData();
    }, [dateRange]);

    const toggleCompanyFilter = () => {
        setCompanyFilterOpen(!isCompanyFilterOpen);
        if (isEntityFilterOpen) setEntityFilterOpen(false);
        if (isDateFilterOpen) setDateFilterOpen(false);
    };

    const toggleEntityFilter = () => {
        setEntityFilterOpen(!isEntityFilterOpen);
        if (isCompanyFilterOpen) setCompanyFilterOpen(false);
        if (isDateFilterOpen) setDateFilterOpen(false);
    };

    const toggleDateFilter = () => {
        setDateFilterOpen(!isDateFilterOpen);
        if (isCompanyFilterOpen) setCompanyFilterOpen(false);
        if (isEntityFilterOpen) setEntityFilterOpen(false);
    };

    const filterData = () => {
        // Actualiza los filtros seleccionados
        setSelectedCompanies(tempSelectedCompanies);
        setSelectedEntities(tempSelectedEntities);

        // Aplica el filtrado basado en los filtros seleccionados y el rango de fechas
        const filteredStaff = staffList.filter(staff => {
            const companyMatch = tempSelectedCompanies.length === 0 || tempSelectedCompanies.includes(staff.companyName);
            const entityMatch = tempSelectedEntities.length === 0 || tempSelectedEntities.includes(staff.entityName);
            const searchMatch = staff.staffName.toLowerCase().includes(searchText.toLowerCase()) ||
                staff.guardName?.toLowerCase().includes(searchText.toLowerCase());

            const dateMatch = dateRange[0] === null || dateRange[1] === null || (
                new Date(staff.date) >= dateRange[0] &&
                new Date(staff.date) <= dateRange[1]
            );

            return companyMatch && entityMatch && searchMatch && dateMatch;
        });

        // Actualiza la lista de visitas filtradas
        setFilteredVisits(filteredStaff);

        // Cierra los dropdowns de filtros
        setCompanyFilterOpen(false);
        setEntityFilterOpen(false);
        setDateFilterOpen(false);
    };

    const clearFilter = (filterType) => {
        if (filterType === "company") {
            setTempSelectedCompanies([]);
            setTempSelectedEntities([]);
        } else if (filterType === "entity") {
            setTempSelectedEntities([]);
        }
    };

    const handleCompanyChange = (e) => {
        const value = e.target.value;
        setTempSelectedCompanies(prev =>
            e.target.checked
                ? [...prev, value]
                : prev.filter(company => company !== value)
        );
    };

    const handleEntityChange = (e) => {
        const value = e.target.value;
        setTempSelectedEntities(prev =>
            e.target.checked
                ? [...prev, value]
                : prev.filter(entity => entity !== value)
        );
    };

    const filteredCompaniesOptions = companyOptions.filter(company =>
        company.toLowerCase().includes(companySearchText.toLowerCase())
    );

    const filteredEntitiesOptions = entityOptions.filter(entity =>
        entity.toLowerCase().includes(entitySearchText.toLowerCase())
    );

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    const handleDateChange = (value) => {
        setDateRange(value);
        setIsOpen(false);
    };

    const clearDateChange = () => {
        setDateRange([null, null]);
    };

    // FUNTION TO GET THE TABLE REPORT
    const getTableReport = async () => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {
            const response = await axios.post(URI_REPORT_VISITS_REQUEST, {
                dataTable: filteredVisits,
                filters: {
                    companyFilter: tempSelectedCompanies,
                    entityFilter: tempSelectedEntities,
                    dateFilter: dateRange
                }
            });

            Swal.fire({
                icon: 'success',
                text: `${response.data.message}`,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            });

            window.open(URI_UPLOADS + response.data.path, "_blank");
        } catch (err) {
            Swal.fire({
                icon: 'error',
                text: 'Failed to generate the report',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            });
        }
    };

    const toggleCalendar = () => {
        setIsOpen(!isOpen); // Alterna entre abrir y cerrar el calendario
    };

    const handleCalendarOpen = () => {
        setIsOpen(true);
    }

    const handleCalendarClose = () => {
        setIsOpen(false); // Asegura que el estado se actualice cuando el calendario se cierre
    };

    return (
        <section className="staffList" ref={staffListRef}>
            <div className="visitsFilters" ref={filterRef}>
                {(userLogged.rol === "Master" || userLogged.rol === "Super admin") && (
                    <div className={`locationFilter ${isCompanyFilterOpen ? 'show' : ''} ${selectedCompanies.length > 0 ? 'activeFilter' : ''}`} onClick={toggleCompanyFilter}>
                        <img src={companyIcon} alt="Company icon" />
                        <span className={`filterLabel ${selectedCompanies.length > 0 ? 'filterLabelActive' : ''}`}>Company</span>
                        <div className={`locationFilterOptions ${isCompanyFilterOpen ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                            <div className="locationFilterOptionsInputContainer">
                                <input
                                    className="locationFilterOptionsInput"
                                    type="text"
                                    placeholder="Search company..."
                                    value={companySearchText}
                                    onChange={(e) => setCompanySearchText(e.target.value)}
                                />
                                <img className="searchIcon" src={searchIcon} width={24} height={24} />
                            </div>
                            <div className="optionsScroll">
                                {filteredCompaniesOptions.map((company, index) => (
                                    <div key={index} className="option">
                                        <input className="supplierCheck" value={company} type="checkbox"
                                            checked={tempSelectedCompanies.includes(company)}
                                            onChange={handleCompanyChange} />
                                        <label htmlFor={`company${index}`}>{company}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="actions">
                                <button onClick={() => clearFilter("company")}>Clear</button>
                                <button onClick={filterData}>Apply</button>
                            </div>
                        </div>
                    </div>
                )}
                {(userLogged.rol === "Master" || userLogged.rol === "Super admin" || userLogged.rol === "Admin client") && selectedCompanies.length > 0 && (
                    <div className={`locationFilter ${isEntityFilterOpen ? 'show' : ''} ${selectedEntities.length > 0 ? 'activeFilter' : ''}`} onClick={toggleEntityFilter}>
                        <img src={entityIcon} alt="Entity icon" />
                        <span className={`filterLabel ${selectedEntities.length > 0 ? 'filterLabelActive' : ''}`}>Entity</span>
                        <div className={`locationFilterOptions ${isEntityFilterOpen ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                            <div className="locationFilterOptionsInputContainer">
                                <input
                                    className="locationFilterOptionsInput"
                                    type="text"
                                    placeholder="Search entity..."
                                    value={entitySearchText}
                                    onChange={(e) => setEntitySearchText(e.target.value)}
                                />
                                <img className="searchIcon" src={searchIcon} width={24} height={24} />
                            </div>
                            <div className="optionsScroll">
                                {filteredEntitiesOptions.map((entity, index) => (
                                    <div key={index} className="option">
                                        <input className="supplierCheck" value={entity} type="checkbox"
                                            checked={tempSelectedEntities.includes(entity)}
                                            onChange={handleEntityChange} />
                                        <label htmlFor={`entity${index}`}>{entity}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="actions">
                                <button onClick={() => clearFilter("entity")}>Clear</button>
                                <button onClick={filterData}>Apply</button>
                            </div>
                        </div>
                    </div>
                )}

                <div className={`locationFilter ${isDateFilterOpen ? 'show' : ''} ${dateRange[0] !== null && dateRange[1] !== null ? 'activeFilter' : ''}`} onClick={toggleDateFilter}>
                    <img src={calendarIcon} alt="Calendar icon" />
                    <span className={`filterLabel ${dateRange[0] !== null && dateRange[1] !== null ? 'filterLabelActive' : ''}`}>Date</span>
                    <div className={`locationFilterOptions ${isDateFilterOpen ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                        <div className="labelDatePickerContainer">
                            <span className="labelDatePicker">From</span>
                            <span className="labelDatePicker">To</span>
                        </div>
                        <div className="datePickerContainer">
                            <DateRangePicker
                                calendarProps={{
                                    prev2Label: null,
                                    next2Label: null,
                                    prevLabel: <img className="calendarArrowIcon" src={calendarArrowLeftIcon} />,
                                    nextLabel: <img className="calendarArrowIcon" src={calendarArrowRightIcon} />
                                }}
                                rangeDivider={
                                    <span
                                        className={`calendarIconSpan ${isOpen ? 'active' : ''}`}
                                        onClick={toggleCalendar}>
                                    </span>
                                }
                                calendarIcon={
                                    <span
                                        className={`calendarIconSpan ${isOpen ? 'active' : ''}`}
                                        onClick={toggleCalendar}>
                                    </span>
                                }
                                dayPlaceholder={"DD"}
                                monthPlaceholder={"MM"}
                                yearPlaceholder={"YYYY"}
                                onChange={handleDateChange}
                                value={dateRange}
                                isOpen={isOpen}
                                onCalendarOpen={handleCalendarOpen}
                                onCalendarClose={handleCalendarClose}
                            />
                        </div>
                        <div className="clearButtonContainer">
                            <button className="clearButton" onClick={clearDateChange}>Clear</button>
                            {/* <button onClick={filterData}>Apply</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="addDocumentHeader">
                <h5>Staff visits</h5>
                <img className="printReport" src={printReport} alt="Prodensa supplier" onClick={() => getTableReport()} />
            </div>

            <table className="tableStaff">
                <thead className="tableHeader">
                    <tr>
                        <th><span>Name</span></th>
                        <th><span>Supplier</span></th>
                        <th><span>Guardia</span></th>
                        {/* <th><span>RFC/CURP</span></th> */}
                        {userLogged.rol === 'Master' || userLogged.rol === 'Super admin' ?
                            <th><span>Company</span></th>
                            : null}
                        {userLogged.rol === 'Master' || userLogged.rol === 'Super admin' || userLogged.rol === 'Admin client' ?
                            <th><span>Entity</span></th>
                            : null}
                        <th>
                            <span>Date</span>
                        </th>
                        {/* <th><span>{countryInformation
							.find(country => country.code.toUpperCase() === supplier.informationCompliance.generalInformation.country.toUpperCase())
							.curpLabel}</span></th> */}
                    </tr>
                </thead>
                <tbody className="visitTableBody">
                    {filteredVisits.map((staff, index) => (
                        <tr key={staff._id} /*onClick={() => handleViewStaff(staff, index)}*/>
                            <td>
                                <div className="infoContainer">
                                    <span>{staff?.staffName}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{staff?.supplierName}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{staff?.guardName || "N/A"}</span>
                                </div>
                            </td>
                            {/* <td>
                                <div className="infoContainer">
                                    <span>{staff.curpORfc}</span>
                                </div>
                            </td> */}
                            {userLogged.rol === 'Master' || userLogged.rol === 'Super admin' ?
                                <td>
                                    <div className="infoContainer">
                                        <span>{staff?.companyName}</span>
                                    </div>
                                </td>
                                : null}
                            {userLogged.rol === 'Master' || userLogged.rol === 'Super admin' || userLogged.rol === 'Admin client' ?
                                <td>
                                    <div className="infoContainer">
                                        <span>{staff?.entityName}</span>
                                    </div>
                                </td>
                                : null}
                            <td>
                                <div className="infoContainer">
                                    <span>{formatDate(staff?.date)}</span>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="staffsContainer d-lg-none">
                {filteredVisits.map((visit, index) => (
                    <div key={index} className="staffInformation">
                        <div className="imssAndRfc">
                            <div>
                                <h5>{visit.staffName}</h5>
                                <span>Name</span>
                            </div>
                            <div>
                                <h5>{visit.curpORfc}</h5>
                                <span>CURP/RFC</span>
                            </div>
                            <div>
                                <h5>{formatDate(visit.date)}</h5>
                                <span>Date</span>
                            </div>
                        </div>
                        <div className="imssAndRfc">
                            {userLogged.rol === 'Master' || userLogged.rol === 'Super admin' ?
                                <div>
                                    <h5>{visit.companyName}</h5>
                                    <span>Company</span>
                                </div>
                                : null}
                            {userLogged.rol === 'Master' || userLogged.rol === 'Super admin' || userLogged.rol === 'Admin client' ?
                                <div>
                                    <h5>{visit.entityName}</h5>
                                    <span>Entity</span>
                                </div>
                                : null}
                        </div>
                    </div>
                ))}
            </div>
        </section >
    )
}