import axios from "axios"

const INDUSTRIES_URL = process.env.REACT_APP_URI_INDUSTRY

const SITUACION_FISCAL_COMPLIANCE_URL = process.env.REACT_APP_URI_VALIDATE_SITUACION_FISCAL
const OPINION_CUMPLIMIENTO_URL = process.env.REACT_APP_URI_VALIDATE_OPINION_CUMPLIMIENTO
const REPSE_URL = process.env.REACT_APP_URI_VALIDATE_REPSE
const OPINION_IMSS_URL = process.env.REACT_APP_URI_VALIDATE_OPINION_IMSS
const OPINION_INFONAVIT_URL = process.env.REACT_APP_URI_VALIDATE_OPINION_INFONAVIT
const SUA = process.env.REACT_APP_URI_VALIDATE_STAFF_DOCUMENT

// DOCUMENT ID OF THE DOCUMENTS THAT NEED VALIDATION OR DO SOMETHING AUTOMATIC
const legalDocumentIDs = {
	constanciaSituacionFiscalFisica: "6564fc579081ec1ad85d7425",
	opicionPositivaSatFisica: "6564fd669081ec1ad85d742a",
	repseFisica: "663bb05df13a85d0d6b3a1d6",
	opinionPositivaImssFisica: "663bb05df13a85d0d6b3a1d3",
	opinionPositivaInfonavitFisica: "663bb05df13a85d0d6b3a1d2",
	suaFisica: "66c8eea61528ec0c0476340e",

	constanciaSituacionFiscalMoral: "6564fd9b9081ec1ad85d7435",
	opicionPositivaSatMoral: "6564fdac9081ec1ad85d7438",
	repseMoral: "6564fd939081ec1ad85d7433",
	opinionPositivaImssMoral: "6564fdb09081ec1ad85d7439",
	opinionPositivaInfonavitMoral: "6564fdb59081ec1ad85d743a",
	suaMoral: "66c8eea61528ec0c0476340f",
}

// GET THE URL FOR THE DOCUMENT VALIDATION
const getDocumentUrl = async (documentID) => {
	switch (documentID) {
		case legalDocumentIDs.constanciaSituacionFiscalFisica:
		case legalDocumentIDs.constanciaSituacionFiscalMoral:
			return SITUACION_FISCAL_COMPLIANCE_URL
		case legalDocumentIDs.opicionPositivaSatFisica:
		case legalDocumentIDs.opicionPositivaSatMoral:
			return OPINION_CUMPLIMIENTO_URL
		case legalDocumentIDs.repseFisica:
		case legalDocumentIDs.repseMoral:
			return REPSE_URL
		case legalDocumentIDs.opinionPositivaImssFisica:
		case legalDocumentIDs.opinionPositivaImssMoral:
			return OPINION_IMSS_URL
		case legalDocumentIDs.opinionPositivaInfonavitFisica:
		case legalDocumentIDs.opinionPositivaInfonavitMoral:
			return OPINION_INFONAVIT_URL
		case legalDocumentIDs.suaFisica:
		case legalDocumentIDs.suaMoral:
			return SUA
		default:
			return ""
	}
}

// FUNCTION TO VALIDATE THE DOCUMENT AND RETURN THE DATA
export const validateDocument = async (document, companyName, supplierRfc) => {
	const url = await getDocumentUrl(document.documentID)

	if (!url) {
		return {
			errorMessage: "We could not validate your document, please try again later \n\n CODE: {URL}"
		}
	}

	const formData = new FormData()
	formData.append("rfc", "temporal")
	formData.append("typeDocument", "SUA");
	formData.append("companyName", companyName)
	formData.append("rfcSupplier", supplierRfc)
	formData.append("document", document.document, `${document.yearCreatedAt}-${document.monthCreatedAt}-${document.document.name}`)
	formData.append("docDateCreation", JSON.stringify({
		year: document.yearCreatedAt,
		month: document.monthCreatedAt,
		day: document.dayCreatedAt,
	}))

	const { data } = await axios.post(url, formData)

	if (data.error) {
		return {
			errorMessage: data.message
		}
	}

	return data
}

// Get all industries
export async function getAllIndustries() {
	try {
		const { data } = await axios.get(INDUSTRIES_URL)

		return data
	} catch (error) {
		console.log("Error al obtener las industrias")
	}
}

// Convert date to a readable format
export function convertDate(date) {
	if (!date) return "N/A"

	const formattedDate = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).replace(/,/g, '')
	return formattedDate;
}