import "../../../assets/css/tabs/staffNew.css";
import "../../../assets/css/tabs/staffList.css";
import countryInformation from "../../../assets/jsonData/countryInformation.json";
import exampleProfilePhoto from "../../../assets/images/profileExample.png";
import deleteIcon from "../../../assets/images/delete.svg";

import { useEffect, useState } from "react";
import ViewStaff from "./viewStaff";
import AddStaff from "./addStaff";
import Swal from "sweetalert2";
import axios from "axios";
import AddMemberAcuse from "./addMemberAcuse";

const STAFF_DOCUMENT_URL = process.env.REACT_APP_URI_VALIDATE_STAFF_DOCUMENT;

export default function StaffList(props) {
	// VARIABLES RECIBIDAS DESDE PROPS
	const { supplier, rfc, serverDate, handleSaveChanges } = props;

	// ESTADO PARA GESTIONAR LA LISTA DE STAFF DEL SUPPLIER
	const [staffList, setStaffList] = useState([]);
	const [filteredStaffList, setFilteredStaffList] = useState([]); // Estado para la lista filtrada

	// ESTADO PARA GESTIONAR EL MODAL DE AÑADIR
	const [showAddModal, setShowAddModal] = useState(false);

	// ESTADO PARA GESTIONAR EL MODAL DE VISTA
	const [staffToShow, setStaffToShow] = useState(null);
	const [staffPosition, setStaffPosition] = useState(null);
	const [showViewModal, setShowViewModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [acuseMembers, setAcuseMembers] = useState([]);

	// ESTADO PARA EL FILTRO DE MIEMBROS INACTIVOS
	const [showInactiveOnly, setShowInactiveOnly] = useState(false);

	function StatusButton({ isActive, onClick }) {
		return (
			<div
				className={`statusSwitch ${isActive ? "active" : ""}`}
				onClick={onClick}
			>
				<div className="statusBall"></div>
			</div>
		);
	}

	// ACTUALIZA LA LISTA DE STAFF CADA VEZ QUE CAMBIA EL SUPPLIER
	useEffect(() => {
		setStaffList([...supplier.staffs]);
	}, [supplier]);

	// FILTRA LA LISTA DE STAFF SEGÚN EL ESTADO DEL CHECKBOX
	useEffect(() => {
		if (showInactiveOnly) {
			setFilteredStaffList(staffList.filter(staff => !staff.active));
		} else {
			setFilteredStaffList(staffList);
		}
	}, [staffList, showInactiveOnly]);

	const handleViewStaff = (staff, position) => {
		setStaffToShow(staff);
		setStaffPosition(position);
		setShowViewModal(true);
	};

	const handleDeleteStaff = (position) => {
		// Mostrar el modal de confirmación
		Swal.fire({
			title: "Are you sure you want to delete this staff member?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			reverseButtons: true,
			confirmButtonText: "Yes, delete",
		}).then((result) => {
			if (result.isConfirmed) {
				// Mostrar el modal de carga mientras se realizan los cambios
				Swal.fire({
					footer: 'Loading, please wait',
					showConfirmButton: false,
					allowOutsideClick: false,
					allowEscapeKey: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});

				// Realizar la eliminación del staff
				const updateSupplier = {
					...supplier,
					staffs: supplier.staffs.filter((_, index) => index !== position),
				};

				handleSaveChanges(updateSupplier)
					.then(() => {
						// Mostrar mensaje de éxito
						Swal.fire({
							icon: 'success',
							text: 'Staff member deleted successfully',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
					})
					.catch((err) => {
						// Mostrar mensaje de error
						Swal.fire({
							icon: 'error',
							text: 'Failed to delete the staff member',
							showConfirmButton: false,
							timer: 5000,
							timerProgressBar: true,
						});
						console.log("Error deleting the staff member: " + err);
					});
			}
		});
	};

	const handleChangeProfilePhoto = (document) => {
		const updateSupplier = JSON.parse(JSON.stringify(supplier));

		const newUpdatedStaff = {
			...updateSupplier.staffs[staffPosition],
			profilePhoto: {
				document,
				yearCreatedAt: serverDate.year,
				monthCreatedAt: serverDate.month,
				dayCreatedAt: serverDate.day,
			},
		};

		updateSupplier.staffs[staffPosition] = newUpdatedStaff;

		handleSaveChanges(updateSupplier);
	};

	const handleSaveChangesWithCURP = (completeUpdatedStaffList) => {
		const updatedSupplier = {
			...supplier,
			staffs: completeUpdatedStaffList,
		};

		handleSaveChanges(updatedSupplier);
	};

	const handleModalClose = () => {
		setShowModal(false);
	};

	const handleAddMemberDocument = async (document, typeDocument) => {
		Swal.fire({
			footer: "Loading, please wait",
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			didOpen: () => {
				Swal.showLoading();
			},
		});

		const documentToValidate = document;

		const formData = new FormData();

		formData.append("rfc", "temporal");
		formData.append("typeDocument", typeDocument);
		formData.append("supplierId", supplier._id);
		formData.append("companyName", supplier.informationCompliance.generalInformation.companyName);
		formData.append("rfcSupplier", supplier.informationCompliance.generalInformation.rfc);
		formData.append("document", documentToValidate, `${documentToValidate.name}`);

		try {
			const response = await axios.post(STAFF_DOCUMENT_URL, formData);
			const errorMessage = response.data?.message;

			if (errorMessage) {
				console.log("errorMessage", errorMessage);
				Swal.fire({
					icon: "error",
					text: errorMessage,
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
					didOpen: () => {
						Swal.hideLoading();
					},
				});
				return;
			}

			const newStaffMembers = response.data.members;

			const updatedStaffList = [];

			if (typeDocument === "SUA") {
				newStaffMembers.forEach((newMember) => {
					const existingMember = staffList.find((member) => member.imss === newMember.imss);
					if (existingMember) {
						updatedStaffList.push({
							...existingMember,
							curp: newMember.curp,
						});
					} else {
						updatedStaffList.push(newMember);
					}
				});

				const updatedSupplier = {
					...supplier,
					staffs: updatedStaffList,
				};
				handleSaveChanges(updatedSupplier);
			} else {
				const updatedStaffList = [...staffList]; // Clonar la lista actual
			
				newStaffMembers.forEach((newMember) => {
					const existingMemberIndex = updatedStaffList.findIndex((member) => member.imss === newMember.imss);
					if (existingMemberIndex >= 0) {
						// Reemplazar el miembro existente con la nueva información
						updatedStaffList[existingMemberIndex] = newMember;
					} else {
						// Agregar nuevo miembro
						updatedStaffList.push(newMember);
					}
				});
			
				// setAcuseMembers(updatedStaffList); // Guardamos todos los nuevos miembros
			
				// const membersWithoutCURP = updatedStaffList.filter(member => !member.curp);
			
				// if (membersWithoutCURP.length > 0) {
				// 	setShowModal(true);
				// } else {
					handleSaveChangesWithCURP(updatedStaffList); // Usar la lista actualizada
				// }
			}

            Swal.close();
		} catch (err) {
			Swal.fire({
				icon: "error",
				text: "Failed to validate document",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
				didOpen: () => {
					Swal.hideLoading();
				},
			});
			console.log("Error al validar el archivo: " + err);
		}
	};

	const handleStatusToggle = (index) => {
		const updatedStaffList = [...staffList];
		updatedStaffList[index].active = !updatedStaffList[index].active;

		// Actualizar el estado con la nueva lista
		setStaffList(updatedStaffList);
		handleSaveChanges({ ...supplier, staffs: updatedStaffList }); // Propaga los cambios
	};

	const handleFilterChange = () => {
		setShowInactiveOnly(!showInactiveOnly);
	};

	return (
		<section className="staffList">
			<div className="addDocumentHeader">
				<h5>Staff members:</h5>
				<div className="rightOptions">
					<div className="filterActiveStaff">
						<input
							className="supplierCheck"
							type="checkbox"
							checked={showInactiveOnly}
							onChange={handleFilterChange}
						/>
						<span className="supplierCheckText">Show inactive members</span>
					</div>
					<button className="btnBgBlueTextGradient" onClick={() => setShowAddModal(true)}>Add Member</button>
				</div>
			</div>

			<table className="tableStaff">
				<thead>
					<tr>
						<th></th>
						<th><span>Name</span></th>
						<th><span>IMSS / Health Insurance Number</span></th>
						<th><span>{countryInformation
							.find(country => country.code.toUpperCase() === supplier.informationCompliance.generalInformation.country.toUpperCase())
							.rfcCurpLabel}</span></th>
						<th><span></span></th>
					</tr>
				</thead>
				<tbody>
					{filteredStaffList.map((staff, index) => (
						<tr key={index} onClick={() => handleViewStaff(staff, index)}>
							<td>
								<div className="infoContainer">
									<img className="profilePhoto"
										src={staff.profilePhoto?.document
											? process.env.REACT_APP_URI_LOCALHOST + staff.profilePhoto?.document
											: exampleProfilePhoto}
										alt="Prodensa Supplier"
									/>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{staff.name}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{staff.imss}</span>
								</div>
							</td>
							<td>
								<div className="infoContainer">
									<span>{staff?.curp || "N/A"}</span>
								</div>
							</td>
							<td>
								<div>
									<StatusButton
										isActive={staff.active}
										onClick={(e) => {
											e.stopPropagation();
											handleStatusToggle(index);
										}}
									/>
									<img className="deleteStaff" src={deleteIcon} alt="Prodensa Supplier"
										onClick={(e) => { e.stopPropagation(); handleDeleteStaff(index) }} />
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<div className="staffsContainer d-lg-none">
				{filteredStaffList.map((staff, index) => (
					<div key={index} className="staffInformation" onClick={() => handleViewStaff(staff, index)}>
						<div className="titleAndPhoto">
							<h5>{staff.name}</h5>
							<span>Name</span>
							<img src={
								staff?.profilePhoto?.document
									? process.env.REACT_APP_URI_LOCALHOST + staff.profilePhoto?.document
									: exampleProfilePhoto}
								alt="Prodensa Supplier"
							/>
						</div>
						<div className="imssAndRfc">
							<div>
								<h5>{staff.imss}</h5>
								<span>IMSS</span>
							</div>
							<div>
								<h5>{staff?.curp || "N/A"}</h5>
								<span>RFC/CURP</span>
							</div>
						</div>
						<div className="imssAndRfc">
							<div>
								<StatusButton
									isActive={staff.active}
									onClick={(e) => {
										e.stopPropagation();
										handleStatusToggle(index);
									}}
								/>
							</div>
						</div>
					</div>
				))}
			</div>

			<AddStaff
				showAddModal={showAddModal}
				onHide={() => setShowAddModal(false)}
				handleAddMemberDocument={handleAddMemberDocument} />

			{staffToShow && <ViewStaff
				showViewModal={showViewModal}
				onHide={() => setShowViewModal(false)}
				staffToShow={staffToShow}
				rfc={rfc}
				supplierId={supplier._id}
				companyName={supplier.informationCompliance.generalInformation.companyName}
				handleChangeProfilePhoto={handleChangeProfilePhoto}
				handleDeleteStaff={() => handleDeleteStaff(staffPosition)} />}
			{/* <AddMemberAcuse
				showAddModal={showModal}
				onHide={handleModalClose}
				acuseMembers={acuseMembers}
				staffList={staffList}
				handleSaveChanges={handleSaveChangesWithCURP}
			/> */}
		</section>
	);
}