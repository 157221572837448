import { addMonths, format } from "date-fns"
import Axios from "axios"

const ACTION_URL = process.env.REACT_APP_CREATE_ACTION

export function addMonthsToDate(stringDate, monthsToAdd) {
    const [month, _day, year] = stringDate.split("/").map(num => parseInt(num.trim()))
    const initialDate = new Date(year, month - 1, 1)

    const newDate = addMonths(initialDate, monthsToAdd)

    return format(newDate, "M/d/yyyy")
}

export function isSameMonth(stringDate, monthCreatedAt) {
    const monthValueOfDate = parseInt(stringDate.split("/")[0])

    if (monthValueOfDate === monthCreatedAt) return stringDate

    const stringDateParts = stringDate.split("/")

    stringDateParts[0] = monthCreatedAt.toString()

    return stringDateParts.join("/")
}

export async function addAction(action, supplierId) {
    try {
        const dataToSend = {
            action,
            supplierId
        }

        const { data } = await Axios.post(ACTION_URL, dataToSend)

        return data
    } catch (error) {
        if (error.response) {
            console.log(error.response.data.message)
            return
        }

        if (error.request) {
            console.log("No response received: ", error.request)
            return
        }

        console.log("Error setting up a request: ", error.message)
    }
}

export const COMMODITIES_OPTIONS = [
    "Abrasives",
    "Acoustical Tile, Insulating Materials and Supplies",
    "Addressing, Copying, Duplicating Machine Supplies",
    "Agricultural Crops, Grains, Fruit, Nuts, and Vegetables",
    "Agricultural Equipment and Accessories",
    "Agricultural Equipment and Implement Parts",
    "Air Compressors and Accessories",
    "Air Conditioning, Heating, Ventilating Equipment",
    "Aircraft and Airport Equipment, Parts, and Supplies",
    "Amusement, Decorations, Entertainment, Gifts, Toys",
    "Animals, Birds, Marine Life, and Poultry, Live",
    "Appliances and Equipment, Household Type",
    "Art Equipment and Supplies",
    "Art Objects",
    "Auto Accessories: Cars, Buses, Trailers, Trucks",
    "Automotive and Trailer Equipment and Parts",
    "Automotive and Trailer Bodies, Accessories, Parts",
    "Automotive Vehicles, Related Transportation Equipment",
    "Automobiles, School Buses, SUVs and Vans",
    "Trucks including Diesel, Gasoline, Electric, Hybrid",
    "Trailers",
    "Automotive Shop, Related Equipment and Supplies",
    "Awards, Badges, Recognitions, Plaques",
    "Bags, Bagging, Ties, and Erosion Sheeting, etc",
    "Bakery Equipment, Commercial",
    "Barber and Beauty Shop Equipment and Supplies",
    "Barrels, Drums, Kegs, and Containers",
    "Bearings see Class 060 for Wheel Bearings",
    "Belts and Belting: Automotive and Industrial",
    "Biochemicals, Research",
    "Boats, Motors, and Marine Equipment",
    "Bookbinding Supplies",
    "Bricks, Clay, Refract Material, Stone, Tile Products",
    "Broom, Brush and Mop MFG Machinery and Supplies",
    "Brushes see Class 485 for Janitorial Type",
    "Builder's Supplies",
    "Buildings, Structures: Fabricated and Prefab",
    "Butcher Shop and Meat Processing Equipment",
    "Cafeteria and Kitchen Equipment, Commercial",
    "Chemical Laboratory Equipment and Supplies",
    "Chemical Raw Materials: MFG, Janitorial, Laundry",
    "Chemicals and Solvents, Commercial in Bulk",
    "Cleaning Products, Detergents, Solvents, Strippers",
    "Clinical Laboratory Reagents and Tests",
    "Clocks, Watches, Jewelry and Precious Stones",
    "Clothing; Athletic, Uniform, Weather, Work",
    "Clothing Accessories",
    "Computer PC's and Peripherals",
    "Computer Hardware",
    "Computer Hardware, Peripherals, Mini, Main Frame",
    "Computer Accessories and Supplies",
    "Computer Software: Microcomputers",
    "Computer Software: Mini/ Main Frame Computers",
    "Concrete/ Metal Products, Culverts, Pilings, Septic",
    "Controlling, Monitoring, Recording Instruments",
    "Coolers, Drinking Water, Water Fountains",
    "Crafts, General",
    "Crafts, Specialized",
    "Cutlery, Cookware, Dishes, Glassware, Utensils",
    "Dairy Equipment and Supplies",
    "Data Processing Cards and Paper",
    "Decals and Stamps",
    "Defense System Equipment, Weapons, Accessories",
    "Dental Equipment and Supplies",
    "Draperies, Curtains and Upholstery Material",
    "Drugs and Pharmaceuticals",
    "Drug and Feeding Administration, Irrigation Equip, Supplies",
    "Eighteenth 18th Century Reproduction Goods",
    "Electrical Cables and Wires not Electronic",
    "Electrical Equipment, Supplies not Cable and Wire",
    "Electronic Equipment, Parts and Accessories",
    "Energy Collecting Equipment, Accessories, Solar, Wind",
    "Elevators, Escalators, Moving Walks, Building Type",
    "Engineering, Architect, Surveying Equipment/ Supplies",
    "Envelopes, Plan",
    "Environmental Protective Equipment Inside/Outside",
    "Epoxy Based Formulations: Adhesive, Coatings",
    "Fare Collection Equipment and Supplies",
    "Fasteners: Bolts, Nuts, Pins, Rivets, Screws, etc",
    "Feed, Bedding, Vitamins, Supplements for Animals",
    "Fencing",
    "Fertilizers and Soil Conditioners",
    "Fire Protection Equipment and Supplies",
    "First Aid and Safety Equipment and Supplies",
    "Flags, Flag Poles, Banners, and Accessories",
    "Floor Covering: Install, Removal, Accessories",
    "Floor Maintenance Machines, Parts, Accessories",
    "Food Processing and Canning Equipment, Supplies",
    "Foods: Bakery Products Fresh",
    "Foods: Dairy Products Fresh",
    "Foods: Frozen",
    "Foods: Perishable",
    "Foods: Staple Grocery and Grocer's Miscellaneous Items",
    "Forms, Continuous: Computer Paper, Form Labels",
    "Foundry Castings, Equipment and Supplies",
    "Fuel, Oil, Grease and Lubricants",
    "Furniture: Health Care, Hospital, Doctor's Office",
    "Furniture: Laboratory",
    "Furniture: Cafeteria, Dorm, Library, Lobby, School",
    "Furniture: Office",
    "Gases, Containers, Equipment: Lab, Medical, Welding",
    "Germicides, Cleaners, Related Sanitation Products",
    "Glass and Glazing Supplies",
    "Hand Tools, Accessories and Supplies",
    "Hardware and Related Items",
    "Hose, Supplies: Industrial, Commercial, Garden",
    "Hospital, Surgical Equipments, Supplies",
    "Hospital, Specialized Equipment: Handicapped, Disabled",
    "Hospital, Medical Accessories, Sundry Items",
    "Janitorial Supplies, General Line",
    "Lab Equipment, Supplies: Analytical and Research",
    "Lab Equipment, Supplies: Biochemistry, Chemistry, Science",
    "Lab Field Equipment, Supplies: Biology, Geology, Zoo",
    "Laundry, Dry Cleaning Equipment, Supplies, Commercial",
    "Laundry, Dry Cleaning Compounds, Detergents, Supplies",
    "Laundry Textiles and Supplies",
    "Lawn Maintenance Equipment and Accessories",
    "Leather and Shoe Accessories, Equipment, Supplies",
    "Library and Archival Equipment, Machines, Supplies",
    "Luggage, Brief Cases, Purses and Related Items",
    "Lumber, Siding, and Related Products",
    "Machinery and Hardware, Industrial",
    "Markers, Plaques and Traffic Control Devices",
    "Manufacturing Components and Supplies",
    "Metal, Paper, Plastic Stencils, Stenciling Devices",
    "Mass Transportation-Transit Bus",
    "Mass Transportation-Transit Bus Parts",
    "Mass Transportation-Rail Vehicles and Systems",
    "Mass Transportation-Rail Vehicles Parts, Accessories",
    "Material Handling, Conveyors, Storage Equipment",
    "Mattress, Pillow Manufacturing Machinery, Supplies",
    "Metals: Bars, Plates, Rods, Sheets, Strips, Tubing",
    "Microfiche, Microfilm Equipment, Accessories, Supplies",
    "Miscellaneous Products, Not Otherwise Classified",
    "Musical Instruments, Accessories and Supplies",
    "Notions, Related Sewing Accessories, Supplies",
    "Nuclear Equipment Components, Accessories, Supplies",
    "Nursery/Plants Stock, Equipment and Supplies",
    "Office Machines, Equipment and Accessories",
    "Office Mechanical Aids, Small Machines, Apparatus",
    "Office Supplies: Carbon Paper and Ribbons",
    "Office Supplies, General",
    "Office Supplies: Erasers, Inks, Leads, Pens, Pencils",
    "Optical Equipment, Accessories and Supplies",
    "Paint, Protective Coatings, Varnish, Wallpaper",
    "Painting Equipment and Accessories",
    "Paper and Plastic Products, Disposable",
    "Paper for Office and Print Shop Use",
    "Park, Recreational, Swimming Pool Equipment, Supplies",
    "Personal Hygiene, Grooming Equipment, Supplies",
    "Photographic Equipment, Film and Supplies",
    "Pipe, Tubing, and Accessories not fittings",
    "Pipe and Tubing Fittings",
    "Pipe, Tobaccos, Smoking Access; Alcoholic Beverages",
    "Plastics, Resins, Fiberglass",
    "Plumbing Equipment, Fixtures and Supplies",
    "Poisons: Agricultural and Industrial",
    "Police Equipment and Supplies",
    "Poultry Equipment and Supplies",
    "Power Generation Equipment, Accessories, Supplies",
    "Power Transmission Equipment",
    "Printing Plant Equipment and Supplies",
    "Prosthetic Devices, Hearing/Reading Aids",
    "Publications, Audiovisual Materials, Books",
    "Pumping Equipment and Accessories",
    "Phone, Phone Equip, Access, Radio Comm Supplies",
    "Telephone Cellular",
    "Radio/Telecomm Testing, Measuring, Analyzing Equipment",
    "Rags, Shop Towels, and Wiping Cloths",
    "Refrigeration Equipment and Accessories",
    "Road/Hwy Building Materials Asphaltic",
    "Road/Hwy Building Materials not Asphaltic",
    "Road/Hwy Asphalt, Concrete Handling, Processing Equipment",
    "Road/Hwy Equipment: Earth Handling, Grading, Moving",
    "Road/Hwy Equipment Except Equipment in 755 and 760",
    "Roofing Material and Supplies",
    "Salt: Sodium Chloride",
    "Scales and Weighing Apparatus",
    "School Equipment, Teaching Aids, and Supplies",
    "Seed, Sod, Soil and Inoculants",
    "Sewing and Textile Machinery and Accessories",
    "Shoes and Boots",
    "Signs, Sign Materials, Sign Making Equipment, etc",
    "Sound Systems, Components and Accessories",
    "Spacecrafts, Accessories and Components",
    "Sporting Goods, Athletic, Athletic Facility Equipment",
    "Spraying Equipment: Household, Nursery Plant, Paint",
    "Steam/Hot Water Fittings, Accessories and Supplies",
    "Steam/Hot Water Boilers, Steam Heating Equipment",
    "Stockman Equipment and Supplies",
    "Tanks: Mobile, Stationary, Underground",
    "Tape, not Measuring/Optical/Sewing/Sound/Video",
    "Television Equipment and Accessories",
    "Testing Apparatus and Instruments",
    "Textiles, Fibers, Household linens, Piece Goods",
    "Theatrical Equipment and Supplies",
    "Tickets, Coupon Books, Sales Books, etc",
    "Tires and Tubes including Recapped/ Retreaded Tires",
    "Train Controls, Electronic",
    "Twine and String",
    "Venetian Blinds, Awnings, and Shades",
    "Veterinary Equipment and Supplies",
    "Visual Education Equipment and Supplies",
    "Voice Response Systems",
    "Water and Wastewater Treating Chemicals",
    "Water Supply, Sewage Treatment, Related Equipment",
    "Welding Equipment and Supplies",
    "X-Ray/ Radiological Equipment and Supplies",
    "Aircraft and Airport Operations Services",
    "Architectural Services, Professional",
    "Architectural/ Engineering Services, Non-Professional",
    "Bookbinding and Repairing Services",
    "Building Construction Services, New",
    "Building Maintenance, Install, Repair Services",
    "Construction Services, General",
    "Construction Services, Heavy",
    "Construction Services, Trade New Construction",
    "Communications and Media Related Services",
    "Consulting Services",
    "Data Process, Computer, Programming, Software Services",
    "Educational Services",
    "Engineering Services, Professional",
    "Environment and Ecological Services",
    "Equipment Maintenance, Repair Services: Autos, Trailers, Buses",
    "Equipment Maintenance/ Repair Services: AG, Construction, Mat Handling",
    "Equipment Maintenance/ Repair Services: Appliance/ Athletic/ Musical",
    "Equipment Maintenance/ Repair Services: Laundry/ Lawn/ Painting",
    "Equipment Maintenance/ Repair Services: General Equipment",
    "Equipment Maintenance/ Repair Services: Hospital. Lab, Testing",
    "Equipment Maintenance/ Repair Services: Office, Photo, Radio",
    "Equipment Maintenance, Repair, Construction for Railroads",
    "Equipment Maintenance/ Repair: Power Generation/ Transmission",
    "Farming and Ranching Services, Animal and Crop",
    "Fishing, Hunting, Trapping, Game Propagation",
    "Steam/Hot Water Fittings, Accessories and Supplies",
    "Steam/Hot Water Boilers, Steam Heating Equipment",
    "Stockman Equipment and Supplies",
    "Tanks: Mobile, Stationary, Underground",
    "Tape, not Measuring/Optical/Sewing/Sound/Video",
    "Television Equipment and Accessories",
    "Testing Apparatus and Instruments",
    "Textiles, Fibers, Household linens, Piece Goods",
    "Theatrical Equipment and Supplies",
    "Tickets, Coupon Books, Sales Books, etc",
    "Tires and Tubes including Recapped/ Retreaded Tires",
    "Train Controls, Electronic",
    "Twine and String",
    "Venetian Blinds, Awnings, and Shades",
    "Veterinary Equipment and Supplies",
    "Visual Education Equipment and Supplies",
    "Voice Response Systems",
    "Water and Wastewater Treating Chemicals",
    "Water Supply, Sewage Treatment, Related Equipment",
    "Welding Equipment and Supplies",
    "X-Ray/ Radiological Equipment and Supplies",
    "Aircraft and Airport Operations Services",
    "Architectural Services, Professional",
    "Architectural/ Engineering Services, Non-Professional",
    "Bookbinding and Repairing Services",
    "Building Construction Services, New",
    "Building Maintenance, Install, Repair Services",
    "Construction Services, General",
    "Construction Services, Heavy",
    "Construction Services, Trade New Construction",
    "Communications and Media Related Services",
    "Consulting Services",
    "Data Process, Computer, Programming, Software Services",
    "Educational Services",
    "Engineering Services, Professional",
    "Environment and Ecological Services",
    "Equipment Maintenance, Repair Services: Autos, Trailers, Buses",
    "Equipment Maintenance/ Repair Services: AG, Construction, Mat Handling",
    "Equipment Maintenance/ Repair Services: Appliance/ Athletic/ Musical",
    "Equipment Maintenance/ Repair Services: Laundry/ Lawn/ Painting",
    "Equipment Maintenance/ Repair Services: General Equipment",
    "Equipment Maintenance/ Repair Services: Hospital. Lab, Testing",
    "Equipment Maintenance/ Repair Services: Office, Photo, Radio",
    "Equipment Maintenance, Repair, Construction for Railroads",
    "Equipment Maintenance/ Repair: Power Generation/ Transmission",
    "Farming and Ranching Services, Animal and Crop",
    "Fishing, Hunting, Trapping, Game Propagation",
    "Adhesives",
    "Aluminum Extrusion",
    "ASSEMBLIES Tank",
    "ASSEMBLIES Tubes & Mfle",
    "ASSEMBLIES Valves",
    "Cable & Wire Harness Assemblies",
    "Casting",
    "Cooler (Stamping)",
    "Cosmetic finishing",
    "Crankshafts",
    "DC Link (Capacitores)",
    "DECLINED",
    "Die Cast",
    "Electronic Contract Manufacturing",
    "EMC Filter (Filtros)",
    "Extrusion/Die Cast",
    "Extrusions (Sub Tier under Machining)",
    "Fasteners",
    "Glass",
    "Gypsum",
    "HOSE/RUBBER",
    "Ignition Coil",
    "Injection Molding",
    "Joining",
    "Joining (Secondary Process under Fabrication & Machinig)",
    "Machining",
    "Machining Parts",
    "Magnets",
    "Mechanical And High Level Assembly",
    "Metal Fabrication",
    "Metal Fabrication / Light Coil",
    "METAL STAMPING & FABRICATIONS",
    "PACKAGING",
    "Packaging/Labels",
    "Plastics",
    "Plastics (rotomolding)",
    "Plastics Injection Molding",
    "Plywood / MDF",
    "Roll Former",
    "Rubber Parts",
    "Sheet metal",
    "Sintered Parts",
    "Steel Coil",
    "Structural Foam Molding",
    "Sttator Assy",
    "Switch",
    "Thermo Forming/Pressure or vacuum",
    "Weldment Assy",
    "Wheels"
]