import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

import LandingCattenna from './pages/landingCattenna.jsx';
import LandingClient from './pages/landingClient.jsx';
import Login from "./pages/login.jsx";
import Home from "./pages/home.jsx";
import Dashboard from "./components/dashboard/dashboard.jsx";
import Suppliers from "./components/suppliers/suppliers.jsx";
import SupplierProfile from "./components/suppliers/suppliersProfile.jsx";
import Industries from "./components/industries/industries.jsx";
import Requests from "./components/requests/requests.jsx";
import ReviewForAuth from "./components/requests/reviewForAuth.jsx";
import ComplianceSetup from "./components/complianceSetup/complianceSetup.jsx";
import MyProfile from "./components/myProfile/myProfile.jsx";
import NotFoundUrl from "./pages/404.jsx";
import IndustryConfiguration from "./components/industries/industryConfiguration.jsx";
import Maintenance from "./pages/maintenance.jsx";
import ClientsAdminView from "./components/clientsAdminView/clientsAdminView.jsx";
import RegisterClient from "./components/clientsAdminView/registerClient.jsx";
import EditClient from "./components/clientsAdminView/editClient.jsx";
import ClientAlerts from "./components/clientAlerts/clientAlerts.jsx";
import ClientsSupplierView from "./components/clientsSupplierView/clientsSupplierView.jsx";
import ClientInformation from "./components/clientsSupplierView/clientInformation.jsx";
import SupplierAlerts from "./components/supplierAlerts/supplierAlerts.jsx";
import SecurityStaff from './components/securityStaff/securityStaff.jsx';

const URI_MAINTENANCE = process.env.REACT_APP_URI_MAINTENANCE_MODE

export default function App() {
	const [maintenance, setMaintenance] = useState(null)

	useEffect(() => {
		getMaintenance()
	}, [])

	const getMaintenance = () => {
		axios.get(URI_MAINTENANCE).then(response => {
			setMaintenance(response.data)
		}).catch(err => {
			console.log("Error al obtener el modo mantenimiento: " + err)
		})
	}

	return (
		<React.Fragment>
			<BrowserRouter>
				{maintenance && <Routes>
					<Route path='/' element={maintenance.maintenanceMode ? <Maintenance /> : <LandingCattenna />} />
					<Route path={maintenance.maintenanceMode ? '/maintenance/:clientUrl' : '/:clientUrl'} element={<LandingClient maintenanceMode={maintenance.maintenanceMode} />} />
					<Route path={maintenance.maintenanceMode ? '/maintenance/login' : '/login'} element={<Login />} />
					<Route path={maintenance.maintenanceMode ? '/maintenance/home' : '/home'} element={<Home maintenanceMode={maintenance.maintenanceMode} />}>
						<Route path='complianceSetup' element={<ComplianceSetup />} />
						<Route path='myProfile' element={<MyProfile />} />
						<Route path='dashboard' element={<Dashboard />} />
						<Route path='suppliers' element={<Suppliers />} />
						<Route path='suppliers/supplierProfile/:id' element={<SupplierProfile />} />
						<Route path='industries' element={<Industries />} />
						<Route path='industries/:id' element={<IndustryConfiguration />} />
						<Route path='requests' element={<Requests />} />
						<Route path='requests/reviewForAuth/:id' element={<ReviewForAuth />} />
						<Route path='clients' element={<ClientsAdminView />} />
						<Route path='clients/registerClient' element={<RegisterClient />} />
						<Route path='clients/clientProfile/:id' element={<EditClient />} />
						<Route path='alerts' element={<ClientAlerts />} />
						<Route path='myClients' element={<ClientsSupplierView />} />
						<Route path='myClients/clientInformation/:id' element={<ClientInformation />} />
						<Route path='myAlerts' element={<SupplierAlerts />} />
						<Route path='securityStaff' element={<SecurityStaff />} />
					</Route>
					<Route path='/404' element={<NotFoundUrl />} />
					<Route path='*' element={maintenance.maintenanceMode ? <Maintenance /> : <Navigate to={"/404"} />} />
				</Routes>}
			</BrowserRouter>
			<TawkMessengerReact
				propertyId={process.env.REACT_APP_PROPERTYID}
				widgetId={process.env.REACT_APP_WIDGETID} />
		</React.Fragment>
	)
}