import { useState, useMemo, useEffect } from "react"
import { useCookies } from "react-cookie"
import { Modal } from "react-bootstrap"

import { formatCommas } from "../../../utilities/formatCommas.js"
import { convertDate } from "../documentCompliance/utils.js"
import { PRODUCTS_TABS, TABLE_TAB_HEADER, PO_STATUS, ABLE_TO_DO_ROLS } from "./utils.js"
import close from "../../../assets/images/close.svg"
import dots from "../../../assets/images/moreDots.svg"
import sortIcon from "../../../assets/images/sort.svg"
import { htmlOrderTemplate } from "../../../utilities/pdfOrderTemplate.js"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Axios from "axios"

export default function ProdAsignTable(props) {
    const {
        // supplier,
        tab,
        supplierProducts,
        items,
        // selectedWarehouse,
        // setSelectedWarehouse,
        showMoreOptions,
        handleMoreOptions,
        handleUpdateOrderStatus,
        // handleSaveChanges,
    } = props

    const [showModal, setShowModal] = useState(false)
    const [modalInfo, setModalInfo] = useState(null)
    const [commentText, setCommentText] = useState('');

    const [sort, setSort] = useState("ASC")
    const [sortType, setSortType] = useState("name")

    const [cookie] = useCookies(["userLogged"])
    const [isStatusModalActive, setIsStatusModalActive] = useState(false);
    const userLogged = useMemo(() => cookie?.userLogged, [cookie?.userLogged]);
    const ableToDo = ABLE_TO_DO_ROLS.includes(userLogged?.rol)

    const prodTab = tab === PRODUCTS_TABS[0]
    const poTab = tab === PRODUCTS_TABS[1]

    useEffect(() => {
        if (tab === PRODUCTS_TABS[0]) {
            setSortType("name")
        } else {
            setSortType("locationName")
        }

        setSort("ASC")
    }, [tab])

    // const handleStock = (value, itemToUpdate) => {
    //     const itemsCopy = [...items]
    //     const itemToUpdateIndex = itemsCopy.findIndex(i => i.barcode === itemToUpdate.barcode)
    //     itemsCopy[itemToUpdateIndex].stock = value

    //     const selectedWarehouseCopy = { ...selectedWarehouse }
    //     selectedWarehouseCopy.products = itemsCopy
    //     setSelectedWarehouse(selectedWarehouseCopy)
    // }

    // const handleStockSave = () => {
    //     const warehousesCopy = [...supplier.warehouses]
    //     const warehouseIndex = warehousesCopy.findIndex(i => i._id === selectedWarehouse._id)
    //     warehousesCopy[warehouseIndex] = selectedWarehouse

    //     const supplierToSave = {
    //         ...supplier,
    //         warehouses: warehousesCopy
    //     }

    //     handleSaveChanges(supplierToSave)
    // }

    const handleSortItems = (property) => {
        let newSort
        if (sortType === property) {
            newSort = sort === "ASC" ? "DSC" : "ASC"
        } else {
            newSort = "ASC"
        }
        setSort(newSort)

        setSortType(property)
    }

    const handleOrderModal = (order) => {
        if (prodTab) return

        setModalInfo(order)
        setShowModal(true)
    }

    const handleDownloadPO = async (orderId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PURCHASING_LOCALHOST}order/${orderId}/createOrderPdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `Order_${orderId}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to download PDF:', error);
        }
    };

    const handleTextareaChange = (e) => {
        setCommentText(e.target.value);
    };

    const sendComment = () => {
        const orderNote = commentText;
        const username = modalInfo?.supplier?.name;
        Axios.put(`${process.env.REACT_APP_PURCHASING_LOCALHOST}order/${modalInfo?._id}/updateOrderNote`, {
            orderNote,
            username
        }).then(res => {
            setModalInfo(prev => {
                const newComments = prev.orderNote;
                newComments.push({
                    text: orderNote,
                    username,
                });
                return { ...prev, orderNote: newComments };
            });
        }).catch(err => {
            console.log(err)
        })
        setCommentText('');
    }

    const address = `${modalInfo?.address} ${modalInfo?.zipCode} ${modalInfo?.county}, ${modalInfo?.state}`
    const orderStatusClass = `order-status ${modalInfo?.status === "Canceled" || modalInfo?.status === "Order in dispute" ? "red" : (modalInfo?.status !== "Order completed" ? "orange" : "")}`

    return (
        <section className="prodAsign-table">
            <div className={`table-header ${tab} d-none d-lg-grid`}>
                {TABLE_TAB_HEADER[tab].map(item => {
                    return (
                        <div key={`${tab}-${item.property}`} className="header-item-container">
                            <span className="header-item">{item.title}</span>
                            <img src={sortIcon} alt="Prodensa Supplier" onClick={() => handleSortItems(item.property)} />
                        </div>
                    )
                })}
            </div>

            <div className={`table-section ${tab}`}>
                {
                    items.sort((a, b) => {
                        const itemInfoA = prodTab ? supplierProducts.find(prod => prod._id === a._id) : a
                        const itemInfoB = prodTab ? supplierProducts.find(prod => prod._id === b._id) : b

                        if (sort === "ASC") {
                            return itemInfoA?.[sortType]?.localeCompare(itemInfoB?.[sortType])
                        } else {
                            return itemInfoB?.[sortType]?.localeCompare(itemInfoA?.[sortType])
                        }
                    })
                        .map(item => {
                            const itemInfo = prodTab ? supplierProducts.find(prod => prod._id === item._id) : item

                            const element1Class = `element-1 ${poTab ? "withCursor" : ""}`
                            const element2Info = prodTab ? itemInfo?.[TABLE_TAB_HEADER?.[tab]?.[2]?.property] : convertDate(itemInfo?.[TABLE_TAB_HEADER?.[tab]?.[3]?.property])
                            const moreOptionsClass = `more-options ${showMoreOptions === itemInfo?.folio ? "show" : ""}`
                            const elementPOClass = `element-po ${itemInfo?.status !== "Order completed" &&
                                itemInfo?.status !== "Canceled" &&
                                itemInfo?.status !== "Order in dispute" ? "orange" :
                                (itemInfo?.status === "Canceled" || itemInfo?.status === "Order in dispute" ? "red" : "")}`

                            const enableMoreOptions = poTab && (itemInfo?.status !== "Order completed" && itemInfo?.status !== "Canceled")
                            const moreOptionsContainerClass = `more-options-container ${enableMoreOptions ? "" : "not-allowed"}`

                            return (
                                <div className={`table-element ${tab}`} key={itemInfo?.[TABLE_TAB_HEADER?.[tab]?.[0]?.property]}>
                                    <span className={element1Class} onClick={() => handleOrderModal(item)}>{itemInfo?.[TABLE_TAB_HEADER?.[tab]?.[0]?.property]}</span>
                                    <span className={`element-header ${element1Class}`} onClick={() => handleOrderModal(item)}>{itemInfo?.[TABLE_TAB_HEADER?.[tab]?.[1]?.property]}</span>
                                    {/* <div className={`inputContainer ${tab}`}>
                                <input type="text" 
                                    value={item?.stock && formatCommas(item.stock).formattedValue} 
                                    onChange={(e) => handleStock(+formatCommas(e.target.value).numericValue, item)}
                                    onBlur={handleStockSave}
                                    disabled={!ableToDo}
                                />
                            </div> */}
                                    <span className={`element-variable ${tab} ${element1Class}`} onClick={() => handleOrderModal(item)}>{itemInfo?.[TABLE_TAB_HEADER?.[tab]?.[2]?.property]}</span>
                                    <span className={`element-2 ${element1Class}`} onClick={() => handleOrderModal(item)}>{element2Info}</span>
                                    {poTab && <span className={elementPOClass}>{itemInfo?.[TABLE_TAB_HEADER?.[tab]?.[4]?.property]}</span>}
                                    {poTab && ableToDo &&
                                        <div className={moreOptionsContainerClass} onClick={() => enableMoreOptions && handleMoreOptions(itemInfo?.folio)}>
                                            <img className="more-dots" src={dots} alt="Prodensa Supplier" />
                                            <div className={moreOptionsClass}>
                                                <span onClick={() => handleUpdateOrderStatus(itemInfo, PO_STATUS.inPreparation)}>In preparation</span>
                                                <span onClick={() => handleUpdateOrderStatus(itemInfo, PO_STATUS.sent)}>Sent</span>
                                                <span onClick={() => handleUpdateOrderStatus(itemInfo, PO_STATUS.delivered)}>Delivered</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })}
            </div>

            {/* Modal for purchase order */}
            <Modal className="document-modal po-modal" centered show={showModal} onHide={() => {
                setShowModal(false)
                setIsStatusModalActive(false);
            }} onClick={() => setIsStatusModalActive(false)}>
                <img className="closeModal" src={close} alt="Catenna Platform" onClick={() => {
                    setShowModal(false)
                    setIsStatusModalActive(false);
                }} />

                <Modal.Header>
                    <Modal.Title>
                        <h4>Purchase order #{modalInfo?.folio}</h4>
                    </Modal.Title>
                    <div className="order-top-options">
                        <div className="order-status-container no-select" onClick={(e) => {
                            e.stopPropagation();
                            setIsStatusModalActive(prev => !prev)
                        }}>
                            <span className={orderStatusClass}>{modalInfo?.status}</span>
                            <div>
                                <span className="status-button-icon"></span>
                            </div>
                            {isStatusModalActive &&
                                <div className="status-options-modal">
                                    <span className="status-option" onClick={() => handleUpdateOrderStatus(modalInfo, PO_STATUS.inPreparation)}>In preparation</span>
                                    <span className="status-option" onClick={() => handleUpdateOrderStatus(modalInfo, PO_STATUS.sent)}>Sent</span>
                                    <span className="status-option" onClick={() => handleUpdateOrderStatus(modalInfo, PO_STATUS.delivered)}>Delivered</span>
                                </div>
                            }
                        </div>
                        <button className="download-po-button" onClick={() => handleDownloadPO(modalInfo?._id)}>
                            <span className="download-po-button-text">Download P.O.</span>
                            <span className="download-po-button-icon"></span>
                        </button>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div className="order-information-main-container">
                        <div className="order-information-subcontainer">
                            <div className="order-information-subcontainer-items">
                                <h5>Supplier</h5>
                                <div className="order-information-container" >
                                    <span className="rfc">{modalInfo?.supplier?.rfc}</span>
                                    <span className="supplier-name">{modalInfo?.supplier?.name}</span>
                                </div>
                            </div>
                            <div className="order-information-subcontainer-items">
                                <h5>Client</h5>
                                <div className="order-information-container">
                                    <span className="location">{modalInfo?.locationName}</span>
                                    <span className="address">{address}</span>
                                </div>
                            </div>
                        </div>
                        <div className="order-information-subcontainer">
                            <div className="product-list-container">
                                <div className="product-list-header">
                                    <h5 className="product-list-header-item">Barcode</h5>
                                    <h5 className="product-list-header-item">Product</h5>
                                    <h5 className="product-list-header-item">Qty</h5>
                                    <h5 className="product-list-header-item">Unit price</h5>
                                    <h5 className="product-list-header-item">Total</h5>
                                </div>
                                <div className="product-list">
                                    {modalInfo?.products.map(prod => {
                                        return (
                                            <div key={prod._id} className="product">
                                                <div className="product-item-wrapper barcodeItem">
                                                    <span className="product-item">{prod.specificProduct?.barcode}</span>
                                                    <div className="product-label" data-label="Barcode"></div>
                                                </div>
                                                <div className="product-item-wrapper productNameItem">
                                                    <h5 className="product-item productNameItem">{prod.specificProduct?.name}</h5>
                                                    <div className="product-label" data-label="Product"></div>
                                                </div>
                                                <div className="product-item-wrapper">
                                                    <span className="product-item">{prod.quantity}</span>
                                                    <div className="product-label" data-label="Qty."></div>
                                                </div>
                                                <div className="product-item-wrapper">
                                                    <span className="product-item">${prod.specificProduct?.price && formatCommas(prod.specificProduct?.price).formattedValue}</span>
                                                    <div className="product-label" data-label="Unit price"></div>
                                                </div>
                                                <div className="product-item-wrapper">
                                                    <span className="product-price product-item">${prod.totalProductPrice && formatCommas(prod.totalProductPrice).formattedValue}</span>
                                                    <div className="product-label" data-label="Total"></div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="total-cost">
                                    <span>Total order price:</span>
                                    <span>${modalInfo?.totalOrderPrice && formatCommas(modalInfo?.totalOrderPrice).formattedValue}</span>
                                </div>
                            </div>
                        </div>
                        <span className="comments-label">Comments</span>
                        <div className="order-information-subcontainer">
                            <div className="comments-container">
                                {modalInfo?.orderNote?.slice().reverse().map((comment) =>
                                    <div className="order-information-container">
                                        <span className="comment">{`${comment.username} : ${comment.text}`}</span>
                                    </div>
                                )}
                            </div>
                            <div className="add-comment-container">
                                <textarea className="add-comment-textarea" placeholder="Write a comment..." value={commentText} onChange={handleTextareaChange}></textarea>
                                <button className="add-comment-button" onClick={() => sendComment()}>
                                    <span className="add-comment-button-text">
                                        Send comment
                                    </span>
                                </button>
                            </div>
                            <button className="download-po-button-mobile" onClick={() => handleDownloadPO(modalInfo?._id)}>
                                <span className="download-po-button-text">Download P.O.</span>
                                <span className="download-po-button-icon"></span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}
