import "../../assets/css/requests/requests.css"

import client from "../../assets/images/clients.svg"
import industry from "../../assets/images/industries.svg"
import status from "../../assets/images/status.svg"
import location from "../../assets/images/location.svg"
import sortIcon from "../../assets/images/sort.svg"
import printReport from "../../assets/images/printReport.svg"
import viewProfile from "../../assets/images/viewProfile.svg"
import next from "../../assets/images/arrowForward.svg"
import cvpBadge from "../../assets/images/CVPBagdeTable.png"

import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import NewRequest from "./newRequest.jsx"
import axios from "axios"
import moment from "moment"
import { useCookies } from "react-cookie"
import Swal from "sweetalert2"

const URI = process.env.REACT_APP_URI_SUPPLIERS_FILTER_BY_STATUS_ARRAY
const URI_CLIENT = process.env.REACT_APP_URI_CLIENT
const URI_SUPPLIER_NEW_REQUEST = process.env.REACT_APP_URI_SUPPLIERS_NEW_REQUEST
const URI_REPORT_SUPPLIER_REQUEST = process.env.REACT_APP_REPORT_SUPPLIER_REQUEST
const URI_UPLOADS = process.env.REACT_APP_URI_UPLOADS

export default function Requests() {
    // VARIABLE TO KNOW WHOS IS LOGGED
    const [cookie] = useCookies(["userLogged"])
    const userLogged = cookie.userLogged

    // VARIABLE TO CONTROL DATA FROM TABLE
    const [dataTable, setDataTable] = useState([])
    const [dataTableCopy, setDataTableCopy] = useState([])

    const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("currentPageRequest") ?? 1)

    let totalPages = Math.ceil(dataTableCopy.length / 20);
    let indexOfLastItem = +currentPage * 20;
    let indexOfFirstItem = indexOfLastItem - 20;
    let currentItems = dataTableCopy.slice(indexOfFirstItem, indexOfLastItem);

    let paginate = (pageNumber) => {
        sessionStorage.setItem("currentPageRequest", pageNumber)
        setCurrentPage(pageNumber);
    };

    // VARIABLE TO CONTROL THE CLIENTS FROM THE DATABASE
    const [clients, setClients] = useState(null)

    // VARIABLE TO MANAGE THE SUPPLIER NEW REQUEST TO CHANGE
    const [suppliersNewRequest, setSuppliersNewRequest] = useState([])

    // VARAIBLE TO CONTROL THE SORT ORDER
    const [order, setOrder] = useState(true)

    // VARIABLES TO CONTROL SORT MENU SHOW AND HIDE
    const [showSortMenu, setShowSortMenu] = useState(false)

    // VARIABLE TO MANAGE FILTER AND MOBILE SORT MENU
    const sortMenuRef = useRef(null)
    const filterRef = useRef(null)

    // VARIABLE TO CONTROL THE LIST OF LOCATIONS
    const [locationList, setLocationList] = useState([])
    const [locationListCopy, setLocationListCopy] = useState([])

    // VARIABLES TO FILTER DATA
    const [clientFilter, setClientFilter] = useState([])
    const [entityFilter, setEntityFilter] = useState([])
    const [industryFilter, setIndustryFilter] = useState([])
    const [statusFilter, setStatusFilter] = useState([])
    const [locationFilter, setLocationFilter] = useState([])

    // VARIABLES TO KNOW IF THE FILTER IS ON
    const [clientIsFiltered, setClientIsFiltered] = useState(false)
    const [entityIsFiltered, setEntityIsFiltered] = useState(false)
    const [industryIsFiltered, setIndustryIsFiltered] = useState(false)
    const [statusIsFiltered, setStatusIsFiltered] = useState(false)
    const [locationIsFiltered, setLocationIsFiltered] = useState(false)

    //VARIABLE TO CONTROL THE FILTER DATA AFTER CLEAR A SINGLE FILTER
    const [hardFilterData, setHardFilterReset] = useState(false)

    //VARIABLE TO SHOW RESET ALL FILTERS
    const [resetAll, setResetAll] = useState(false)

    //VARIABLES TO CONTROL MODAL
    const [supplierID, setSupplierID] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [supplierClientID, setSupplierClientID] = useState(null)

    // VARIABLE TO NAVIGATE
    const navigate = useNavigate();

    // useEffect to add body listener
    useEffect(() => {
        getAllNewRequests()
        getAllClients()

        window.onbeforeunload = clearSessionStorage;

        document.body.addEventListener("click", closeMenusFilters)

        return function cleanUp() {
            window.onbeforeunload = null; // Cleanup event listener
            document.body.removeEventListener("click", closeMenusFilters)
        }
    }, [])

    // COMPARATION TO SHOW THE RESET ALL BUTTON
    useEffect(() => {
        if (clientIsFiltered || entityIsFiltered || industryIsFiltered || statusIsFiltered || locationIsFiltered) {
            setResetAll(true)
        } else {
            setResetAll(false)
        }
    })

    // ACTION TO EXECUTE WHEN YOU CLEAR A SINGLE FILTER TO RESET THE TABLE
    useEffect(() => {
        if (hardFilterData) {
            filterData()
            setHardFilterReset(false)
        }
    }, [hardFilterData])

    // CLEAR SESSION STORAGE ON PAGE REFRESH
    const clearSessionStorage = () => {
        sessionStorage.removeItem('currentPageRequest');
    };

    // FUNCTION TO CLOSE MENU FILTERS
    const closeMenusFilters = (e) => {
        let filtersContainer;
        let menuFiltersContainer;
        const searchContainer = document.getElementsByClassName("searchContainer")

        if (filterRef.current && filterRef.current.lastElementChild && filterRef.current.contains(e.target)) {
            filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("filter"))
            filtersContainer.map(element => {
                if (!filterRef.current.contains(element)) {
                    element.classList.remove("show")
                }
            })
            filterRef.current.classList.toggle("show")

            menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("filterOptions"))
            menuFiltersContainer.map(element => {
                if (!filterRef.current.lastElementChild.contains(element)) {
                    element.classList.remove("show")
                }
            })
            filterRef.current.lastElementChild.classList.toggle("show")

            if (filterRef.current.classList.contains("location")) {
                searchContainer[1].classList.toggle("show")
            } else {
                searchContainer[1].classList.remove("show")
            }
        } else {
            filtersContainer = Array.prototype.slice.call(document.getElementsByClassName("filter"))
            filtersContainer.map(element => {
                element.classList.remove("show")
            })

            menuFiltersContainer = Array.prototype.slice.call(document.getElementsByClassName("filterOptions"))
            menuFiltersContainer.map(element => {
                element.classList.remove("show")
            })

            if (searchContainer[1]) {
                searchContainer[1].classList.remove("show")
            }
        }

        if (sortMenuRef.current && !sortMenuRef.current.contains(e.target)) {
            setShowSortMenu(false)
        }
    }

    // FUNCTION TO SORT TABLE COLUMN
    const sortTable = (order, sortBy) => {
        // ORDER FALSE = ASC; ORDER TRUE = DSC 
        switch (sortBy) {
            case "Name":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.companyName.toLocaleLowerCase();
                    let fb = b.companyName.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Industry":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.industries[0].toLocaleLowerCase();
                    let fb = b.industries[0].toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))
                setOrder(!order)
                break

            case "Location":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.location.toLocaleLowerCase();
                    let fb = b.location.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))
                setOrder(!order)
                break

            case "Status":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let fa = a.entityStatus.toLocaleLowerCase();
                    let fb = b.entityStatus.toLocaleLowerCase();

                    if (!order) {
                        if (fa < fb) {
                            return -1
                        }

                        if (fa > fb) {
                            return 1
                        }
                    } else {
                        if (fa > fb) {
                            return -1
                        }

                        if (fa < fb) {
                            return 1
                        }
                    }

                    return 0
                }))

                setOrder(!order)
                break

            case "Date":
                setDataTableCopy(dataTableCopy.sort((a, b) => {
                    let da = new Date(a.updatedAt);
                    let db = new Date(b.updatedAt);
                    if (!order) {
                        return da - db
                    } else {
                        return db - da
                    }
                }))
                setOrder(!order)
                break

            default:
                break;
        }
    }

    // FUNCTIONS TO ADD FILTERS
    const addClient = (target) => {
        if (clientFilter.some(valueToFind => valueToFind === target.value)) {
            clientFilter.splice(clientFilter.findIndex(valueToFind => valueToFind === target.value), 1)
            setClientFilter(clientFilter)
        } else {
            clientFilter.push(target.value)
            setClientFilter(clientFilter)
        }
    }

    const addEntity = (target) => {
        if (entityFilter.some(valueToFind => valueToFind === target.value)) {
            entityFilter.splice(entityFilter.findIndex(valueToFind => valueToFind === target.value), 1)
            setEntityFilter(entityFilter)
        } else {
            entityFilter.push(target.value)
            setEntityFilter(entityFilter)
        }
    }

    const addIndustry = (target) => {
        if (industryFilter.some(valueToFind => valueToFind === target.value)) {
            industryFilter.splice(industryFilter.findIndex(valueToFind => valueToFind === target.value), 1)
            setIndustryFilter(industryFilter)
        } else {
            industryFilter.push(target.value)
            setIndustryFilter(industryFilter)
        }
    }

    const addStatus = (target) => {
        if (statusFilter.some(valueToFind => valueToFind === target.value)) {
            statusFilter.splice(statusFilter.findIndex(valueToFind => valueToFind === target.value), 1)
            setStatusFilter(statusFilter)
        } else {
            statusFilter.push(target.value)
            setStatusFilter(statusFilter)
        }
    }

    const addLocation = (target) => {
        if (locationFilter.some(valueToFind => valueToFind === target.value)) {
            locationFilter.splice(locationFilter.findIndex(valueToFind => valueToFind === target.value), 1)
            setLocationFilter(locationFilter)
        } else {
            locationFilter.push(target.value)
            setLocationFilter(locationFilter)
        }
    }

    // FUNCTIONS TO DISPLAY THE MODAL
    const activateModal = (data, clientID) => {
        setSupplierID(data)
        setShowModal(true)
        setSupplierClientID(clientID)
    }

    const handleCloseModal = () => {
        setShowModal(false)
        getAllNewRequests();
    }

    // FUNCTION TO GET ALL NEW REQUESTS
    const getAllNewRequests = () => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.post(URI, {
            status: ["New request", "Pre-authorized", "Ready for review", "Review requested"],
            rol: userLogged && userLogged.rol,
            clientID: userLogged && userLogged.dataID
        }).then(response => {
            setDataTable(response.data)
            setDataTableCopy(response.data)
            createLocationFilter(response.data)

            Swal.close()
        }).catch(err => {
            console.log("Error al obtener todas las nuevas solicitudes" + err)
        })
    }

    // FUNCTION TO GET ALL CLIENTS
    const getAllClients = () => {
        axios.get(URI_CLIENT).then(response => {
            setClients(response.data)
        }).catch(err => {
            console.log("Error al obtener los clientes")
        })
    }

    // FUNTION TO GET THE TABLE REPORT
    const getTableReport = () => {
        Swal.fire({
            footer: 'Loading, please wait',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        axios.post(URI_REPORT_SUPPLIER_REQUEST, {
            dataTable: dataTableCopy,
            filters: {
                clientFilter: clientFilter,
                entityFilter: entityFilter,
                industryFilter: industryFilter,
                statusFilter: statusFilter,
                locationFilter: locationFilter
            }
        }).then(response => {
            Swal.fire({
                icon: 'success',
                text: `${response.data.message}`,
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
            window.open(URI_UPLOADS + response.data.path, "_blank")
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                text: 'Failed to generate the report',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
            })
        })
    }

    // FUNCTION TO FILTER THE SUPPLIER REQUEST BY INPUT SEARCH
    const searchFilter = (value) => {
        setCurrentPage(1)
        let dataFiltered = JSON.parse(JSON.stringify(dataTable));

        dataFiltered = dataFiltered.filter(prevState => {
            if (prevState.companyName.toLocaleLowerCase().includes(value.toLocaleLowerCase()) || prevState.description.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
                return prevState
            }
        })

        setDataTableCopy(dataFiltered)
    }

    // FUNCTION TO FILTER THE SUPPLIER REQUEST BY FILTER BUTTONS
    const filterData = () => {
        const generalSearch = document.getElementById("generalSearch")
        generalSearch.value = ""

        let dataFiltered = JSON.parse(JSON.stringify(dataTable));

        if (clientFilter.length > 0) {
            const adminClientEntitiesID = clients.filter(client => clientFilter.includes(client._id)).flatMap(client => client.entities.map(entity => entity._id))

            dataFiltered = dataFiltered.filter(supplier => {
                return adminClientEntitiesID.includes(supplier.entityID)
            })

            setClientIsFiltered(true)
        }

        if (entityFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return entityFilter.includes(supplier.entityID)
            })

            setEntityIsFiltered(true)
        }

        if (industryFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return supplier.industries.some(category => {
                    return industryFilter.includes(category)
                })
            })

            setIndustryIsFiltered(true)
        }

        if (statusFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return statusFilter.includes(supplier.entityStatus)
            })

            setStatusIsFiltered(true)
        }

        if (locationFilter.length > 0) {
            dataFiltered = dataFiltered.filter(supplier => {
                return locationFilter.includes(supplier.location)
            })

            setLocationIsFiltered(true)
        }

        setDataTableCopy(dataFiltered)
    }

    // FUNCTION TO CLEAR THE SUPPLIER REQUEST BY FILTER BUTTONS AND RESET ALL ON DEFAULT CASE
    const clearFilter = (filter) => {
        switch (filter) {
            case "client":
                const clientChecks = Array.prototype.slice.call(document.getElementsByClassName("clientCheck"))

                setClientFilter([])
                clientChecks.forEach(element => {
                    element.checked = false
                });
                setClientIsFiltered(false)
                setHardFilterReset(true)
                break;

            case "entity":
                const entityChecks = Array.prototype.slice.call(document.getElementsByClassName("entityCheck"))

                setEntityFilter([])
                entityChecks.forEach(element => {
                    element.checked = false
                });
                setEntityIsFiltered(false)
                setHardFilterReset(true)
                break;

            case "industry":
                const industryChecks = Array.prototype.slice.call(document.getElementsByClassName("industryCheck"))

                setIndustryFilter([])
                industryChecks.forEach(element => {
                    element.checked = false
                });
                setIndustryIsFiltered(false)
                setHardFilterReset(true)
                break;

            case "status":
                const statusChecks = Array.prototype.slice.call(document.getElementsByClassName("statusCheck"))

                setStatusFilter([])
                statusChecks.forEach(element => {
                    element.checked = false
                });
                setStatusIsFiltered(false)
                setHardFilterReset(true)
                break;

            case "location":
                const locationChecks = Array.prototype.slice.call(document.getElementsByClassName("locationCheck"))

                setLocationFilter([])
                locationChecks.forEach(element => {
                    element.checked = false
                });
                setLocationIsFiltered(false)
                setHardFilterReset(true)
                break;

            default:
                clearFilter("client")
                clearFilter("entity")
                clearFilter("industry")
                clearFilter("status")
                clearFilter("location")
                break;
        }
    }

    // FUNCTION TO CREATE THE ENTITY LIST TO SHOW ON FILTER BUTTON
    const createEntityFilter = () => {
        let filterEntity

        switch (userLogged.rol) {
            case "Master":
            case "Super admin":
                filterEntity = new Set(clients.filter(client => {
                    return clientFilter.includes(client._id)
                }).flatMap(client => client.entities.map(entity => entity)))

                return Array.from(filterEntity)

            case "Admin client":
                filterEntity = new Set(clients.filter(client => {
                    return userLogged.dataID === client._id
                }).flatMap(client => client.entities.map(entity => entity)))

                return Array.from(filterEntity)

            default:
                break;
        }
    }

    // FUNCTION TO CREATE THE INDUSTRY LIST TO SHOW ON FILTER BUTTON
    const createIndustryFilter = () => {
        const filterIndustry = new Set(dataTable.flatMap(supplier => {
            return supplier.industries.map(category => category)
        }))

        return Array.from(filterIndustry)
    }

    // FUNCTION TO CREATE THE STATUS LIST TO SHOW ON FILTER BUTTON
    const createStatusFilter = () => {
        let filterStatus = []

        filterStatus = new Set(dataTable.flatMap(supplier => {
            return supplier.entityStatus
        }))

        return Array.from(filterStatus)
    }

    // FUNCTION TO CREATE THE LOCATION LIST TO SHOW ON FILTER BUTTON
    const createLocationFilter = (data) => {
        const filterLocation = new Set(data.map(supplier => {
            return supplier.location
        }))

        const locationList = Array.from(filterLocation).sort((a, b) => {
            if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) {
                return 1
            }

            if (a.toLocaleLowerCase() < b.toLocaleLowerCase()) {
                return -1
            }

            return 0
        })

        setLocationList(locationList)
        setLocationListCopy(locationList)
    }

    // FUNCTION TO FILTER THE SUPPLIER DIRECTORY BY INPUT SEARCH
    const searchLocation = (value) => {

        let dataFiltered = []
        dataFiltered = locationList.filter(prevState => {
            if (prevState.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
                return prevState
            }

        })

        setLocationListCopy(dataFiltered)
    }

    // FUNCTION TO ADD THE SUPPLIER NEW REQUEST TO CHANGE STATUS
    const addSuppliersNewRequest = (flagState, supplierID, clientID) => {
        const suppliersToChange = JSON.parse(JSON.stringify(suppliersNewRequest))

        if (flagState) {
            if (suppliersToChange && suppliersToChange.some(supplier => supplier.supplierID === supplierID)) {
                suppliersToChange.find(supplier => supplier.supplierID === supplierID).clientIDs.push(clientID)
            } else {
                suppliersToChange.push({
                    supplierID: supplierID,
                    clientIDs: [clientID]
                })
            }
        } else {
            const clientIndexToRemove = suppliersToChange.find(supplier => supplier.supplierID === supplierID).clientIDs.indexOf(clientID)

            suppliersToChange.find(supplier => supplier.supplierID === supplierID).clientIDs.splice(clientIndexToRemove, 1)

            if (suppliersToChange.find(supplier => supplier.supplierID === supplierID).clientIDs.length === 0) {
                const supplierIndexToRemove = suppliersToChange.findIndex(supplier => supplier.supplierID === supplierID)

                suppliersToChange.splice(supplierIndexToRemove, 1)
            }
        }

        setSuppliersNewRequest(suppliersToChange)
    }

    // FUNCTION TO ADD ALL THE SUPPLIERS NEW REQUEST TO CHANGE STATUS
    const addAllSuppliersNewRequest = () => {
        let dataTable = JSON.parse(JSON.stringify(dataTableCopy))
        let suppliersToChange = []

        dataTable.filter(supplier => supplier.entityStatus === "New request").forEach(supplier => {
            if (suppliersToChange.some(supplierToChange => supplierToChange.supplierID === supplier.supplierID)) {
                suppliersToChange.find(supplierToChange => supplierToChange.supplierID === supplier.supplierID).clientIDs.push(supplier.entityID)
            } else {
                suppliersToChange.push({
                    supplierID: supplier.supplierID,
                    clientIDs: [supplier.entityID]
                })
            }
        })

        setSuppliersNewRequest(suppliersToChange)
    }

    // FUNCTION TO HANDLE THE SUPPLIER NEW REQUEST STATUS CHANGE
    const handleChangeSupplierNewRequest = () => {
        Swal.fire({
            title: `Are you sure you want to update (${suppliersNewRequest.flatMap(supplier => supplier.clientIDs).length}) suppliers selected?`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Yes, update",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    footer: 'Loading, please wait',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                })

                axios.post(URI_SUPPLIER_NEW_REQUEST, {
                    suppliersNewRequest: suppliersNewRequest
                }).then(response => {
                    getAllNewRequests()

                    Swal.fire({
                        icon: 'success',
                        text: 'Information submitted successfully',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                }).catch(err => {
                    Swal.fire({
                        icon: 'error',
                        text: 'Failed to save information',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    })
                    console.log("Error al actualizar los suppliers" + err)
                })
            }
        });
    }

    // FUNCTION TO RETURN THE NUMBER OF CLIENTS FROM THE SUPPLIERS TO CHANGE
    const getSupplierNewRequestQuantity = () => {
        let newRequestQuantity = 0

        newRequestQuantity = dataTableCopy.filter(supplier => supplier.entityStatus === "New request").length

        return newRequestQuantity
    }

    return (
        <section className="request">
            <div className="titleAndSearch">
                <h3 className="title">Supplier Requests</h3>
                <div className="searchAndSort">
                    <div className="searchContainer">
                        <input id="generalSearch" type="text" placeholder="Search by name..."
                            onFocus={() => clearFilter()} onChange={(e) => searchFilter(e.target.value)} />
                    </div>
                    <button ref={sortMenuRef} className="d-lg-none sortMobile" onClick={() => setShowSortMenu(true)}>Sort</button>
                    <div className={`d-lg-none sortDataContainer ${showSortMenu ? "show" : ""}`}>
                        <h5 className="title">Sort</h5>
                        <span onClick={() => { sortTable(false, "Name") }}>Name (ascending)</span>
                        <span onClick={() => { sortTable(true, "Name") }}>Name (descending)</span>
                        <span onClick={() => { sortTable(false, "Industry") }}>Industry (ascending)</span>
                        <span onClick={() => { sortTable(true, "Industry") }}>Industry (descending)</span>
                        <span onClick={() => { sortTable(false, "Location") }}>Location (ascending)</span>
                        <span onClick={() => { sortTable(true, "Location") }}>Location (descending)</span>
                        <span onClick={() => { sortTable(false, "Status") }}>Status (ascending)</span>
                        <span onClick={() => { sortTable(true, "Status") }}>Status (descending)</span>
                        <span onClick={() => { sortTable(false, "Date") }}>Date (ascending)</span>
                        <span onClick={() => { sortTable(true, "Date") }}>Date (descending)</span>
                    </div>
                </div>
            </div>
            <div className="filters">
                {(userLogged.rol === "Master" || userLogged.rol === "Super admin") && <div className={`filter ${clientIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
                    <img src={client} alt="Prodensa Supplier" />
                    <span>Client</span>
                    <div className="filterOptions" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={client} alt="Prodensa Supplier" />
                            Client
                        </span>
                        <div className="optionsScroll">
                            {clients && clients.map((client, index) => {
                                if (client.generalInformation.clientName !== "Admin Prodensa") {
                                    return (
                                        <div key={index} className="option">
                                            <input className="clientCheck" value={client._id} type="checkbox" id={`client${index}`} onClick={(e) => addClient(e.target)} />
                                            <label htmlFor={`client${index}`}>{client.generalInformation.clientName}</label>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => { clearFilter("client"); clearFilter("entity"); }}>Clear</button>
                            <button onClick={() => { filterData(); }}>Apply</button>
                        </div>
                    </div>
                </div>}
                {(clientIsFiltered || userLogged.rol === "Admin client") && <div className={`filter ${entityIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
                    <img src={client} alt="Prodensa Supplier" />
                    <span>Entity</span>
                    <div className="filterOptions" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={client} alt="Prodensa Supplier" />
                            Entity
                        </span>
                        <div className="optionsScroll">
                            {clients && createEntityFilter().map((entity, index) => {
                                return (
                                    <div key={index} className="option">
                                        <input className="entityCheck" value={entity._id} type="checkbox" id={`entity${index}`} onClick={(e) => addEntity(e.target)} />
                                        <label htmlFor={`entity${index}`} >{entity.entityName}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("entity")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>}
                <div className={`filter ${industryIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
                    <img src={industry} alt="Prodensa Supplier" />
                    <span>Industry</span>
                    <div className="filterOptions" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={industry} alt="Prodensa Supplier" />
                            Industry
                        </span>
                        <div className="optionsScroll">
                            {createIndustryFilter().map((industry, index) => {
                                return (
                                    <div key={index} className="option">
                                        <input className="industryCheck" value={industry} type="checkbox" id={`industry${index}`} onClick={(e) => addIndustry(e.target)} />
                                        <label htmlFor={`industry${index}`} >{industry}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("industry")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
                <div className={`filter ${statusIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
                    <img src={status} alt="Prodensa Supplier" />
                    <span>Status</span>
                    <div className="filterOptions" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={status} alt="Prodensa Supplier" />
                            Status
                        </span>
                        <div className="optionsScroll">
                            {clients && createStatusFilter().map((filter, index) => (
                                <div key={index} className="option">
                                    <input className="statusCheck" value={filter} type="checkbox" id={`status${index}`} onClick={(e) => addStatus(e.target)} />
                                    <label htmlFor={`status${index}`}>{filter}</label>
                                </div>
                            ))}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("status")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
                <div className={`filter location ${locationIsFiltered ? "active" : undefined}`} onClick={(e) => filterRef.current = e.target}>
                    <img src={location} alt="Prodensa Supplier" />
                    <span>Location</span>
                    <div className="searchContainer" onClick={(e) => e.stopPropagation()}>
                        <input type="text" placeholder="Search cities"
                            onChange={(e) => searchLocation(e.target.value)} />
                    </div>
                    <div className="filterOptions location" onClick={(e) => !e.target.matches("button") && e.stopPropagation()}>
                        <span className="title">
                            <img className="d-lg-none imgMenu" src={location} alt="Prodensa Supplier" />
                            Location
                        </span>
                        <div className="optionsScroll">
                            {locationListCopy.map((filter, index) => (
                                <div key={index} className="option">
                                    <input className="locationCheck" value={filter} type="checkbox" id={`location${index}`} onClick={(e) => addLocation(e.target)} />
                                    <label htmlFor={`location${index}`} >{filter}</label>
                                </div>
                            ))}
                        </div>
                        <div className="actions">
                            <button onClick={() => clearFilter("location")}>Clear</button>
                            <button onClick={() => filterData()}>Apply</button>
                        </div>
                    </div>
                </div>
                <span className={`resetAll ${resetAll ? "show" : ""}`} onClick={() => clearFilter()}>Reset All</span>
                <button className="btnBgBlueTextGradient ms-lg-auto" onClick={() => addAllSuppliersNewRequest()}>
                    Select all new request ({getSupplierNewRequestQuantity()})
                </button>
                <button className={`btnBgBlueTextGradient ${suppliersNewRequest.length > 0 ? "show" : ""}`} onClick={() => handleChangeSupplierNewRequest()}>
                    Change suppliers
                </button>
            </div>
            <div className="newRequestActions">
                <button className="btnBgBlueTextGradient d-lg-none" onClick={() => addAllSuppliersNewRequest()}>
                    Select all new request ({getSupplierNewRequestQuantity()})
                </button>
                <button className={`btnBgBlueTextGradient d-lg-none ${suppliersNewRequest.length > 0 ? "show" : ""}`} onClick={() => handleChangeSupplierNewRequest()}>
                    Change suppliers
                </button>
            </div>
            <span className={`resetAll ${resetAll ? "show" : ""} d-lg-none`} onClick={() => clearFilter()}>Reset All</span>

            <table className="tableRequests">
                <thead>
                    <tr>
                        <th>
                            <span>Name</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Name")} />
                        </th>
                        <th>
                            <span>Industry</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Industry")} />
                        </th>
                        <th>
                            <span>Location</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Location")} />
                        </th>
                        <th>
                            <span>Status</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Status")} />
                        </th>
                        <th>
                            <span>Date</span>
                            <img className="sort" src={sortIcon} alt="Prodensa supplier" onClick={() => sortTable(order, "Date")} />
                            <img className="printReport" src={printReport} alt="Prodensa supplier" onClick={() => getTableReport()} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((supplier, index) => (
                        <tr key={index}>
                            <td>
                                <div className={`infoContainer ${supplier.certified ? "withBadge" : ""}`}>
                                    {supplier.certified && <img className="cvpBadge" src={cvpBadge} alt="Prodensa Cattenna" />}
                                    {supplier.entityStatus === "New request" &&
                                        <input className="massiveAction" type="checkbox" onChange={(e) => addSuppliersNewRequest(e.target.checked, supplier.supplierID, supplier.entityID)}
                                            checked={suppliersNewRequest?.find(supplierRequest => supplierRequest.supplierID === supplier.supplierID)?.clientIDs.includes(supplier.entityID) ?? false}
                                        />}
                                    <span onClick={() => supplier.entityStatus === "New request" ? activateModal(supplier.supplierID, supplier.entityID) : navigate("./reviewForAuth/" + supplier.supplierID)}>
                                        {supplier.companyName}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.industries.map((industry, index) => {
                                        return supplier.industries.length > index + 1 ? industry + ", " : industry
                                    })}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{supplier.location}</span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span className={`${supplier.entityStatus === "Ready for review" ? "success" : ""} ${supplier.entityStatus === "Pre-authorized" || supplier.entityStatus === "Review requested" ? "pending" : ""} ${supplier.entityStatus === "New request" ? "new" : ""}`}>
                                        {supplier.entityStatus}
                                    </span>
                                </div>
                            </td>
                            <td>
                                <div className="infoContainer">
                                    <span>{moment(supplier.updatedAt).format("DD MMM YYYY")}</span>
                                    <img className="viewProfile" src={viewProfile} alt="Prodensa Supplier" onClick={() => supplier.entityStatus === "New request" ? activateModal(supplier.supplierID, supplier.entityID) : navigate("./reviewForAuth/" + supplier.supplierID)} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="d-lg-none supplierRequests">
                {currentItems.map((supplier, index) => (
                    <div key={index} className="requestRow" onClick={() => supplier.entityStatus === "New request" ? activateModal(supplier.supplierID, supplier.entityID) : navigate("./reviewForAuth/" + supplier.supplierID)}>
                        <div className="requestHeader">
                            {supplier.certified && <img className="cvpBadge" src={cvpBadge} alt="Prodensa Cattenna" />}
                            <h5 className="requestName">
                                {supplier.companyName}
                            </h5>
                            {supplier.entityStatus === "New request" &&
                                <input className="massiveAction" type="checkbox" onClick={(e) => e.stopPropagation()} onChange={(e) => addSuppliersNewRequest(e.target.checked, supplier.supplierID, supplier.entityID)}
                                    checked={suppliersNewRequest?.find(supplierRequest => supplierRequest.supplierID === supplier.supplierID)?.clientIDs.includes(supplier.entityID) ?? false}
                                />}
                        </div>
                        <div className="requestInformation">
                            <span className="requestValue">{supplier.location}</span>
                            <span className="requestTitle">Location</span>
                            <span className={`requestValue ${supplier.entityStatus === "Ready for review" ? "success" : ""} ${supplier.entityStatus === "Pre-authorized" || supplier.entityStatus === "Review requested" ? "pending" : ""} ${supplier.entityStatus === "New request" ? "new" : ""}`}>
                                {supplier.entityStatus}
                            </span>
                            <span className="requestTitle">Status</span>
                            <span className="requestValue">{supplier.industries.map((industry, index) => {
                                return supplier.industries.length > index + 1 ? industry + ", " : industry
                            })}</span>
                            <span className="requestTitle">Industry</span>
                            <span className="requestValue">{moment(supplier.updatedAt).format("DD MMM YYYY")}</span>
                            <span className="requestTitle">Date</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="addAndPagination">
                <div className="pagination">
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button key={index} className={`number ${+currentPage === index + 1 ? 'active' : ''}`} onClick={() => paginate(index + 1)}>
                            {index + 1}
                        </button>
                    ))}
                    <img className="nextPage" src={next} alt="Prodensa Supplier" onClick={() => +currentPage < totalPages ? paginate(+currentPage + 1) : undefined} />
                </div>
            </div>
            <span className="tableCount">{currentItems.length < 19 ? currentItems.length : 20 * +currentPage} of {dataTable.length}</span>

            {supplierID && supplierClientID && <NewRequest show={showModal} userLogged={userLogged} supplierID={supplierID} handleClose={() => handleCloseModal()} supplierClientID={supplierClientID} />}

        </section>
    )
}